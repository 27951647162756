<template>
  <div class="sd">
    <PagesSlider :banners="topBanners" :noHost="true" :color="'#830051'" />
    <section class="first-block">
      <div class="content">
        <div class="tap-hint mb-4 mt-4">
          <div class="tap-hint__icon ml-auto mr-2">
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tap.svg`"
            />
          </div>
          <div class="tap-hint__text">Выберите, чтобы узнать больше</div>
        </div>
      </div>
      <div class="fixed-tabs">
        <div class="content">
          <div class="first-block__tabs">
            <div
              class="first-block__arrow first-block__arrow_left"
              @click="
                choiceFirstTab(
                  firstTabsActive > 0 ? firstTabsActive - 1 : firstTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="first-block__tabs-row">
              <div
                class="first-block__tab"
                @click="choiceFirstTab(0)"
                :class="{ 'first-block__tab_active': firstTabsActive === 0 }"
              >
                <div class="first-block__tab-text">
                  Скрининг и диагностика артериальной гипертензии
                </div>
              </div>
              <div
                class="first-block__tab"
                @click="choiceFirstTab(1)"
                :class="{ 'first-block__tab_active': firstTabsActive === 1 }"
              >
                <div class="first-block__tab-text">
                  Факторы риска артериальной гипертензии<sup>1</sup>
                </div>
              </div>
              <div
                class="first-block__tab"
                @click="choiceFirstTab(2)"
                :class="{ 'first-block__tab_active': firstTabsActive === 2 }"
              >
                <div class="first-block__tab-text">
                  Патофизиологические механизмы артериальной гипертензии<sup
                    >6</sup
                  >
                </div>
              </div>
            </div>

            <div
              class="first-block__arrow first-block__arrow_right"
              @click="
                choiceFirstTab(
                  firstTabsActive < 2 ? firstTabsActive + 1 : firstTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="first-block__tab-body">
          <div class="first-block__tab1" v-if="firstTabsActive === 0">
            <div class="first-block__tab1-text">
              <div>
                <div class="fs-18 fw-b mb-5" style="color: #d0006f">
                  Алгоритм скрининга и диагностики артериальной гипертензии
                  согласно клиническим рекомендациям 2024 г.<sup>1</sup>
                </div>
                <div class="fs-18 fw-sb mb-5">
                  Диагностика артериальной гипертензии включает в себя следующие
                  этапы обследования:
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">Сбор жалоб и анамнеза</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">Повторные измерения АД</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">Объективное обследование</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Лабораторно-инструментальные методы исследования: рутинные
                    на первом этапе и сложные — на втором этапе обследования (по
                    показаниям)
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Исключение вторичных (симптоматических) АГ при необходимости
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Оценка общего сердечно-сосудистого риска
                  </div>
                </div>
              </div>
              <picture>
                <source
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab1-1.jpg 2x`"
                  media="(max-width: 767px)"
                />
                <source
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab1-1_tab.jpg 2x`"
                  media="(max-width: 1219px)"
                />
                <source
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab1-1.jpg 2x`"
                  media="(min-width: 1220px)"
                />
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab1-1.jpg 2x`"
                />
              </picture>
            </div>
            <div class="first-block__tab1-warning">
              <div class="first-block__tab1-warning-icon">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/warning.png 1.5x`"
                />
              </div>
              <div class="first-block__tab1-warning-text my-auto">
                Скрининг артериальной гипертензии путем измерения АД в
                медицинском учреждении
                <span style="color: #d0006f">рекомендован</span> всем пациентам
                на обеих руках<sup>1</sup>.
              </div>
            </div>
            <div class="fs-18 fs-i mt-8">
              Артериальная гипертензия диагностируется при повышении САД,
              измеренного в медицинском учреждении до 140 мм рт. ст. и выше
              и/или повышении ДАД до 90 мм рт. ст. и выше на двух разных
              визитах, за исключением тяжелой АГ (АГ 2-3-й степени, особенно у
              пациентов высокого риска) и/или повышения АД при измерении вне
              медицинского учреждения (по данным суточного мониторинга
              артериального давления и/или домашнего мониторинга артериального
              давления)<sup>1</sup>.
            </div>
            <div class="full-screen mt-10">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-1.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-1.jpg
                        `"
                />
              </picture>
            </div>
            <div class="fs-28 ff-rs mt-20 fw-b mb-8" style="text-align: center">
              Классификация стадий АГ в зависимости от уровней АД, наличия
              факторов сердечно-сосудистого риска, поражения органов,
              обусловленного гипертензией, и наличия сопутствующих
              заболеваний<sup>1</sup>
            </div>
            <div class="overflow mb-5">
              <div class="overflow__body">
                <img
                  :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-2.jpg
                      `"
                />
              </div>
            </div>
            <div class="fs-18_s mb-20" style="color: #9c9c9c">
              Примечание: АД – артериальное давление; ДАД – диастолическое
              артериальное давление; ИБС – ишемическая болезнь сердца; САД –
              систолическое артериальное давление; СД – сахарный диабет; ССЗ –
              сердечно-сосудистые заболевания; ПОМ – поражение органов мишеней;
              ХБП – хроническая болезнь почек; ФР – факторы риска.
              <br />
              <br />
              Адаптировано из: Кобалава Ж. Д. и др. Артериальная гипертензия у
              взрослых. Клинические рекомендации 2024 //Российский
              кардиологический журнал. – 2024. – Т. 29. – №. 9. – С. 6117.
            </div>
            <div
              class="fs-28 ff-rs mt-20 fw-b mb-15"
              style="text-align: center"
            >
              Целевые значения артериального давления в зависимости от возраста
              и сопутствующих заболеваний (СД, Инсульт / ТИА, ИБС, ХБП)<sup
                >1</sup
              >
            </div>
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-3_mob.jpg`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-3_tab.jpg`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-3.jpg 2x`"
                media="(min-width: 1220px)"
              />
              <img
                class="mx-auto"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-3.jpg 2x`"
              />
            </picture>
            <div class="fs-18_s mt-5 mb-20" style="color: #9c9c9c">
              Примечание: ДАД – диастолическое артериальное давление; ИБС –
              ишемическая болезнь сердца; САД – систолическое артериальное
              давление; СД – сахарный диабет; ТИА – транзиторная ишемическая
              атака; ХБП – хроническая болезнь почек.
            </div>
            <div class="d-xl-flex mb-8">
              <div class="mr-4 mr-md-0 mb-md-5" style="flex-basis: 45%">
                <div
                  class="pa-10 pa-xs-5"
                  style="
                    border-radius: 30px;
                    border: 1px solid rgb(210, 210, 210);
                    height: 100%;
                  "
                >
                  <div class="fs-23 fw-m mb-6">
                    <span style="color: #d0006f">Первичное</span> обследование
                    для выявления поражения органов-мишеней (ПОМ)<sup>1</sup>:
                  </div>
                  <div class="first-block__line first-block__line_pink">
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg
                            `"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          Регистрация ЭКГ в 12-ти отведениях
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg
                            `"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          Отношение альбумин-креатинин в разовой порции мочи
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg
                            `"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          Креатинин и расчетная скорость клубочковой фильтрации
                          (рСКФ)
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`
                              https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg
                            `"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          Фундоскопия
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-4 ml-md-0" style="flex-basis: 55%">
                <div
                  class="pa-10 pa-xs-5"
                  style="
                    border-radius: 30px;
                    border: 1px solid rgb(210, 210, 210);
                    height: 100%;
                  "
                >
                  <div class="fs-23 fw-m mb-6" style="color: #3e1876">
                    Дополнительные обследования<sup>1</sup>:
                  </div>
                  <div class="first-block__line first-block__line_violet">
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          <b class="fw-b">ЭхоКГ</b> для оценки структуры и
                          функции сердца
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          <b class="fw-b">УЗИ сонных артерий</b> для выявления
                          атеросклеротических бляшек или стенозов сонных артерий
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          <b class="fw-b"
                            >УЗИ органов брюшной полости с дуплексным
                            сканированием брюшной аорты и почечных артерий</b
                          >
                          для исключения сопутствующей патологии
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          <b class="fw-b">Скорость пульсовой волны (СПВ)</b> для
                          оценки артериальной жесткости
                        </div>
                      </div>
                    </div>
                    <div class="first-block__line-item">
                      <div class="first-block__line-item-index">
                        <img
                          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                        />
                      </div>
                      <div class="first-block__line-item-text my-auto">
                        <div class="first-block__line-item-text">
                          <b class="fw-b">Лодыжечно-плечевой индекс (ЛПИ)</b> –
                          скрининг для выявления атеросклероза артерий нижних
                          конечностей
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fs-18">
              Прямая связь между повышенным уровнем АД и риском
              сердечно-сосудистых событий продемонстрирована для всех возрастных
              и этнических групп. При лечении АГ следует учитывать
              индивидуальный уровень СС риска, во время оценки которого врач
              берет во внимание имеющиеся у пациента факторы риска,
              бессимптомное поражение органов-мишеней, ассоциированные
              клинические состояния<sup>1</sup>.
            </div>
            <input type="checkbox" id="first-block_tab1" class="d-none" />
            <label
              for="first-block_tab1"
              class="first-block__tab1-show button button_pink-light mx-auto mt-4 px-10 py-4 fs-18"
            >
              Подробнее об оценке сердечно-сосудистого риска
            </label>
            <label
              @click="firstBlockTab1Hide()"
              for="first-block_tab1"
              class="first-block__tab1-hide button button_empty-pink-light mx-auto mt-4 px-10 py-4 fs-18"
            >
              Скрыть
            </label>
            <div class="first-block__tab1-hidden mt-15">
              <div class="fs-28 ff-rs fw-m mb-8" style="text-align: center">
                Этапы оценки сердечно-сосудистого риска<sup>1</sup>
              </div>
              <div class="overflow mb-5">
                <div class="overflow__body">
                  <img
                    :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-4.jpg
                      `"
                  />
                </div>
              </div>
              <div class="fs-18 mt-4" style="color: #9c9c9c">
                Примечание: АГ – артериальная гипертензия; АД – артериальное
                давление; АКС – ассоциированное клиническое состояние; ПОМ –
                поражение органов-мишеней; СД – сахарный диабет; ССЗ –
                сердечно-сосудистое заболевание; ХБП – хроническая болезнь
                почек; ХС-ЛНП – холестерин липопротеидов низкой плотности; ФП –
                фибрилляция предсердий; ФР – факторы риска.
              </div>
              <div
                class="fs-28 ff-rs fw-m mb-8 mt-20"
                style="text-align: center"
              >
                Определение сердечно-сосудистого для Российской Федерации.
                <br />
                Шкалы SCORE2 и SCORE2-OP<sup>4,5</sup>
              </div>
              <div class="fs-18 mt-8" style="text-align: center">
                10-летний риск (фатальных и нефатальных) КВ событий в популяции
                <span class="fw-b" style="color: #cf1517">очень высокого</span>
                риска развития ИБС
              </div>
              <picture>
                <source
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-5_mob.jpg`"
                  media="(max-width: 767px)"
                />

                <source
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-5.jpg 2x`"
                  media="(min-width: 1220px)"
                />
                <img
                  class="mx-auto mt-8"
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-5.jpg 2x`"
                />
              </picture>
              <div class="full-screen mt-5">
                <div
                  class="full-screen__trigger d-md-none"
                  :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-6.jpg
                      `"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <picture>
                  <img
                    :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-6.jpg
                        `"
                  />
                </picture>
              </div>
              <div class="fs-18 mt-4" style="color: #9c9c9c">
                Адаптировано из: 1. SCORE2 working group and ESC Cardiovascular
                risk collaboration, SCORE2 risk prediction algorithms: new
                models to estimate 10-year risk of cardiovascular disease in
                Europe, European Heart Journal, Volume 42, Issue 25, 1 July
                2021, Pages
                2439–2454, https://doi.org/10.1093/eurheartj/ehab309.
                <br />
                2. SCORE2-OP working group and ESC Cardiovascular risk
                collaboration, SCORE2-OP risk prediction algorithms: estimating
                incident cardiovascular event risk in older persons in four
                geographical risk regions, European Heart Journal, Volume 42,
                Issue 25, 1 July 2021, Pages
                2455–2467, https://doi.org/10.1093/eurheartj/ehab312.
              </div>
              <div
                class="fs-28 ff-rs fw-m mb-8 mt-20"
                style="text-align: center"
              >
                Шкала глобальной оценки 10-летнего сердечно-сосудистого
                риска<sup>1</sup>
              </div>
              <div class="overflow mb-5">
                <div class="overflow__body">
                  <img
                    :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block_tab1-7.jpg
                      `"
                  />
                </div>
              </div>
              <div class="fs-18 mt-4" style="color: #9c9c9c">
                Примечание: АГ — артериальная гипертензия; ХБП — хроническая
                болезнь почек; СКФ — скорость клубочковой фильтрации; ТИА —
                транзиторная ишемическая атака; CCЗ — сердечно-сосудистые
                заболевания; АСБ — атеросклеротическая бляшка; СГХС — семейная
                гиперхолестеринемия; ФР — фактор риска.
                <br />
                <br />
                Адаптировано из: Диагностика и коррекция нарушений липидного
                обмена с целью профилактики и лечения атеросклероза. Российские
                рекомендации, VII пересмотр. Атеросклероз и дислипидемии.
                2020;1(38):7–42. [Diagnostics and correction of lipid metabolism
                disorders in order to prevent and treat of atherosclerosis
                Russian recommendations VII revision. The Journal of
                Atherosclerosis and Dyslipidemias (JAD). 2020;1(38):7–42 (in
                Russ.)]. DOI: 10.34687/2219-8202.JAD.2020.01.0002.
              </div>
              <label
                for="first-block_tab1"
                @click="firstBlockTab1Hide()"
                class="button button_empty-pink-light mx-auto mt-4 px-10 py-4 fs-18"
              >
                Скрыть
              </label>
            </div>
          </div>
          <div v-if="firstTabsActive === 1">
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-1_tab.jpg`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-1.jpg 2x`"
                media="(min-width: 1220px)"
              />
              <img
                class="mx-auto d-none d-md-block mt-10 mb-20"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-1.jpg 2x`"
              />
            </picture>
            <div class="overflow mb-5 mt-10 d-md-none">
              <div class="overflow__body">
                <img
                  :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-1-1_mob.jpg
                      `"
                />
              </div>
            </div>
            <img
              class="d-md-none mb-15"
              :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-1-2_mob.jpg
                      `"
            />
            <div
              class="fs-28 ff-rs fw-m mb-8"
              style="text-align: center; color: #d0006f"
            >
              Признаки вторичной артериальной гипертензии<sup>1</sup>
            </div>
            <div class="first-block__tab1-text">
              <div>
                <div class="fs-18 fw-sb mb-5">
                  При выявлении следующих признаков требуется дополнительное
                  обследование с целью исключения причин вторичной артериальной
                  гипертензии:
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    АГ 2-й степени у пациентов моложе 40 лет или любое повышение
                    АД у детей
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Внезапное острое развитие АГ у пациентов с ранее
                    документированной стойкой нормотонией
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">Резистентная АГ</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    АГ 3-й степени или гипертонический криз
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Признаки значительных изменений в органах-мишенях
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Клинические или лабораторные признаки, позволяющие
                    подозревать эндокринные причины АГ или ХБП
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Клинические симптомы синдрома обструктивного апноэ сна
                    (СОАС)
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_purple.svg`"
                      style="width: 20px"
                    />
                  </div>
                  <div class="fs-18 ff-r my-auto">
                    Симптомы, позволяющие заподозрить феохромоцитому, или
                    семейный анамнез данной опухоли
                  </div>
                </div>
              </div>
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab2-2.jpg 2x`"
              />
            </div>
          </div>
          <div v-if="firstTabsActive === 2">
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Стойкое и длительное повышение уровня АД обусловлено изменением
              соотношения 3 гемодинамических показателей:
            </div>
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-1_mob.jpg`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-1_tab.jpg`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-1.jpg 2x`"
                media="(min-width: 1220px)"
              />
              <img
                class="mx-auto mb-15"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-1.jpg 2x`"
              />
            </picture>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-2.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-2.jpg
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-20" style="color: #9c9c9c">
              Адаптировано из: Volpe M., Com J., Coca A., Unger T. The
              cardiovascular continuum refined: A hypothesis. Blood Pressure.
              2010;19(5):273–277. doi: 10.3109/08037051.2010.481072.
            </div>
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Наиболее важными патогенетическими звеньями формирования и
              прогрессирования эссенциальной АГ являются<sup>6</sup>:
            </div>
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-3_mob.jpg`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-3_tab.jpg`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-3.jpg 2x`"
                media="(min-width: 1220px)"
              />
              <img
                class="mx-auto mb-20"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-3.jpg 2x`"
              />
            </picture>
            <div
              class="fs-28 ff-rs fw-m mb-8 mt-10"
              style="text-align: center; color: #d0006f"
            >
              Механизмы регуляции артериального давления и патофизиология
              артериальной гипертензии<sup>7</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-4.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  :src="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-4.jpg
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-20" style="color: #9c9c9c">
              Адаптировано из: Mancia Chairperson G. et al. 2023 ESH Guidelines
              for the management of arterial hypertension The Task Force for the
              management of arterial hypertension of the European Society of
              Hypertension Endorsed by the European Renal Association (ERA) and
              the International Society of Hypertension (ISH) //Journal of
              hypertension. – 2023. – Т. 41. – №. 12. – С. 1874-2071.
            </div>
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Мозаичная теория формирования артериальной гипертензии<sup>8</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-5.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-5.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-10" style="color: #9c9c9c">
              Адаптировано из: Page IH. Pathogenesis of arterial hypertension. J
              Am Med Assoc 1949;140:451–8.
            </div>
            <div class="fs-18 mb-20">
              Этиология большинства случаев артериальной гипертензии является
              многофакторной и что генетические, экологические, анатомические,
              адаптивные, нервные, эндокринные, гуморальные и гемодинамические
              факторы синергично взаимодействуют, вызывая повышение АД. На
              основании этой работы возникла парадигма, согласно которой общие
              молекулярные процессы протекают в различных органах, включая
              головной мозг, почки, сосуды и сердце, с нарушением их структуры и
              функции. «Мозаичная теория» дает понимание того, почему уровень АД
              наиболее эффективно контролируется с помощью комбинации
              антигипертензивных препаратов с разными механизмами
              действия<sup>1</sup>.
            </div>
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Роль ренин-ангиотензин-альдостероновой системы (РААС) в повышении
              АД<sup>9</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-6.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-6.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-20" style="color: #9c9c9c">
              Примечание: 🠇 = уменьшение; 🠅 = увеличение; + = активировать; – =
              ингибировать.
              <br />
              <br />
              Адаптировано из: Awosika A, Khan A, Adabanya U, Omole AE, Millis
              RM. Aldosterone Synthase Inhibitors and Dietary Interventions: A
              Combined Novel Approach for Prevention and Treatment of
              Cardiovascular Disease. Cureus. 2023 Mar 15;15(3):e36184. doi:
              10.7759/cureus.36184.
            </div>
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Роль альдостерона в повышении АД, повреждении органов-мишеней и
              активации иммунной системы<sup>10</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-7.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-7.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-10" style="color: #9c9c9c">
              Примечание: АД – артериальное давление; АФК – активные формы
              кислорода; МФ – макрофаги; ДК – дендритные клетки; ТФР –
              трансформирующий фактор роста; ФНО – фактор некроза опухоли; Тц –
              цитотоксические Т-клетки; Тх – Т-хелперы; ИЛ – интерлейкин.
              <br />
              <br />
              Адаптировано из: Ferreira NS, Tostes RC, Paradis P, Schiffrin EL.
              Aldosterone, Inflammation, Immune System, and Hypertension. Am J
              Hypertens. 2021;34(1):15-27. doi:10.1093/ajh/hpaa137.
            </div>
            <div class="fs-18 mb-20">
              Альдостерон представляет собой минералокортикоидный гормон,
              регулирующий баланс жидкости и электролитов в организме. Избыток
              альдостерона может привести к развитию сердечно-сосудистых и
              метаболических заболеваний. <br />
              <br />
              Воспаление играет ключевую роль в повреждении сосудов, вызванном
              альдостероном, усугубляя сосудистые аномалии, включая
              эндотелиальную дисфункцию, ремоделирование сосудов, фиброз и
              окислительный стресс. Эти процессы, в свою очередь, могут привести
              к различным повреждениям органов-мишеней. <br />
              <br />
              В последние годы было проведено множество исследований,
              подтверждающих, что альдостерон активирует клетки врождённой и
              адаптивной иммунной системы, что еще сильнее сказывается на
              поражении сердечно-сосудистой системы. <br />
              <br />
              Кроме того, альдостерон активирует различные подтипы врождённых
              иммунных клеток, такие как дендритные клетки и моноциты/макрофаги,
              а также адаптивные иммунные клетки, такие как Т-лимфоциты.
              Стимулируя минералокортикоидные рецепторы, альдостерон
              способствует выработке провоспалительных факторов транскрипции,
              молекул адгезии, воспалительных цитокинов и
              хемокинов<sup>10</sup>.
            </div>
            <div class="fs-28 ff-rs fw-m mb-8 mt-10" style="text-align: center">
              Патологические механизмы, анатомические изменения и
              сердечно-сосудистые последствия, опосредованные альдостероном<sup
                >11</sup
              >
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-8.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/first-block__tab3-8.jpg 2x
                        `"
                />
              </picture>
            </div>

            <div class="fs-18 mb-20">
              Альдостерон способствует развитию сердечно-сосудистых заболеваний
              посредством множественных патологических механизмов, приводящих к
              атеросклерозу коронарных артерий, фиброзу миокарда и гипертрофии,
              а также электрофизиологическим изменениям с последующим повышением
              риска сердечно-сосудистых событий<sup>11</sup>.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="second-block tab-pinned" id="hba1c">
      <div class="content">
        <div class="tap-hint mb-4 mt-4">
          <div class="tap-hint__icon ml-auto mr-2">
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tap.svg`"
            />
          </div>
          <div class="tap-hint__text">Выберите, чтобы узнать больше</div>
        </div>
      </div>
      <div class="fixed-tabs sticky">
        <div class="content">
          <div class="second-block__tabs">
            <div
              class="second-block__arrow second-block__arrow_left"
              @click="
                choiceSecondTab(
                  secondTabsActive > 0 ? secondTabsActive - 1 : secondTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="second-block__tabs-row">
              <div
                class="second-block__tab"
                @click="choiceSecondTab(0)"
                :class="{ 'second-block__tab_active': secondTabsActive === 0 }"
              >
                <div class="second-block__tab-icon">
                  <img
                    v-if="secondTabsActive === 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tabs2-1_active.svg`"
                  />
                  <img
                    v-if="secondTabsActive !== 0"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tabs2-1.svg`"
                  />
                </div>
                <div class="second-block__tab-text">
                  Артериальная гипертензия и хроническая болезнь почек
                </div>
              </div>
              <div
                class="second-block__tab"
                @click="choiceSecondTab(1)"
                :class="{ 'second-block__tab_active': secondTabsActive === 1 }"
              >
                <div class="second-block__tab-icon">
                  <img
                    v-if="secondTabsActive === 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tabs2-2_active.svg`"
                  />
                  <img
                    v-if="secondTabsActive !== 1"
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tabs2-2.svg`"
                  />
                </div>
                <div class="second-block__tab-text">
                  Артериальная гипертензия и сахарный диабет<sup>14</sup>
                </div>
              </div>
            </div>

            <div
              class="second-block__arrow second-block__arrow_right"
              @click="
                choiceSecondTab(
                  secondTabsActive < 2 ? secondTabsActive + 1 : secondTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="second-block__tabs-body">
          <div class="mt-10" v-if="secondTabsActive === 0">
            <div class="d-xl-flex mb-20">
              <div class="mr-4 mr-md-0">
                <div
                  class="px-6 py-9 mb-5"
                  style="
                    border-radius: 0 24px 24px 0;
                    box-shadow: 0px 40px 50px -30px #00000059;
                    border-left: 7px solid #d0006f;
                    background-color: #fff;
                  "
                >
                  <div class="fs-23 ff-rs fw-m mb-5">
                    Всем пациентам с АГ для выявления нарушения функции почек,
                    <span style="color: #d0006f"
                      >оценки сердечно-сосудистого риска</span
                    >
                    рекомендуются<sup>1</sup>:
                  </div>
                  <div class="fs-18">
                    <ul style="list-style-type: disc; margin-left: 0.75em">
                      <li class="mb-3" style="color: #d0006f">
                        <span style="color: #3c4242"
                          >Исследование уровня креатинина в сыворотке
                          крови;</span
                        >
                      </li>
                      <li style="color: #d0006f">
                        <span style="color: #3c4242"
                          >Расчёт скорости клубочковой фильтрации (СКФ) в
                          мл/мин/1.73м&#178; по формуле CKD-EPI.</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  class="d-xl-none mb-5"
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-1_tab.jpg 2x`"
                />
                <div
                  class="px-6 py-9 mb-5"
                  style="
                    border-radius: 0 24px 24px 0;
                    box-shadow: 0px 40px 50px -30px #00000059;
                    border-left: 7px solid #d0006f;
                    background-color: #fff;
                  "
                >
                  <div class="fs-23 ff-rs fw-m mb-5">
                    Всем пациентам с АГ для выявления заболеваний почек и
                    <span style="color: #d0006f">оценки ССР</span> рекомендуется
                    проводить<sup>1</sup>:
                  </div>
                  <div class="fs-18">
                    <ul style="list-style-type: disc; margin-left: 0.75em">
                      <li class="mb-3" style="color: #d0006f">
                        <span style="color: #3c4242"
                          >Общий (клинический) анализ мочи с микроскопическим
                          исследованием осадка мочи;</span
                        >
                      </li>
                      <li class="mb-3" style="color: #d0006f">
                        <span style="color: #3c4242"
                          >Определение микроальбуминурии/альбуминурии;</span
                        >
                      </li>
                      <li style="color: #d0006f">
                        <span style="color: #3c4242"
                          >Исследованием креатинина в моче с расчетом отношения
                          альбумин/креатинин.</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                class="mr-4 mr-md-0 d-none d-xl-block"
                style="flex-basis: 354px; flex-shrink: 0"
              >
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-1.jpg 2x`"
                />
              </div>
            </div>
            <div class="fs-28 ff-rs mt-20 fw-b mb-8" style="text-align: center">
              Кардиоренальный континуум<sup>12</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-2.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-2.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-10" style="color: rgb(156, 156, 156)">
              Адаптировано из: Zyryanov S.K., Baybulatova E.A. Drug adjustment
              of modified risk factors as one of the leading strategies for
              managing patients with cardiovascular diseases. Meditsinskiy sovet
              = Medical Council. 2019;(21):22-38. (In Russ.) doi:
              10.21518/2079-701X-2019-21-22-38
            </div>
            <div class="fs-18 mb-20">
              Кардиоренальный континуум представляет собой установленную связь
              между функцией почек и сердечно-сосудистыми заболеваниями. ССЗ и
              ХБП имеют общие факторы риска и патофизиологические механизмы.
              Изменения, вызванные дисфункцией почек, ускоряют прогрессирование
              ССЗ и наоборот. Лечение, направленное на сердечно-сосудистую или
              почечную дисфункцию, улучшает функционирование обеих систем и
              прогноз пациента.
            </div>
            <div class="overflow mb-5">
              <div class="overflow__body">
                <img
                  :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-3.jpg
                    `"
                />
              </div>
            </div>
            <div class="fs-18_s mb-10" style="color: rgb(156, 156, 156)">
              Адаптировно из: KDIGO 2024 Clinical Practice Guideline for the
              Evaluation and Management of Chronic Kidney Disease Stevens, Paul
              E. et al. Kidney International, Volume 105, Issue 4, S117 - S314
            </div>
            <div class="fs-28 ff-rs mt-20 fw-b mb-8" style="text-align: center">
              Лечение АГ при ХБП<sup>1</sup>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-4.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab1-4.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-10" style="color: rgb(156, 156, 156)">
              Примечание: &#185; — у пациентов с ХБП, получающих АГТ, особенно
              иАПФ или БРА, можно ожидать повышения уровня креатинина.
              Значительное повышение уровня креатинина на фоне назначения иРААС
              требует обследования пациента для исключения реноваскулярного
              заболевания. &#178; — риск гиперкалиемии при назначении
              спиронолактона**, особенно при исходной СКФ &lt;45 мл/мин/1,73 м2
              и уровне калия 4,5 ммоль/л. Во всех случаях титровать до полной
              дозировки при переносимости.
              <br />
              Сокращения: АД — артериальное давление; АК — антагонисты кальция;
              ББ — бета-адреноблокаторы; БРА — блокаторы рецепторов
              ангиотензина; иАПФ — ингибиторы ангиотензинпревращающего фермента;
              иНГЛТ-2 – ингибиторы натрий-глюкозного котранспортера 2-го типа;
              САД — систолическое артериальное давление; Т/ТП —
              тиазидный/тиазидоподобный; ФК — фиксированная комбинация; ЧСС —
              частота сердечных сокращений.
            </div>
          </div>
          <div class="mt-10 second-block__tab2" v-if="secondTabsActive === 1">
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-1.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-1.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-20" style="color: rgb(156, 156, 156)">
              Примечание: Метаболические, гемодинамические и воспалительные
              процессы, лежащие в основе патофизиологии ХБП.
              <br />
              AA – афферентная артериола; AGE – конечные продукты повышенного
              гликирования; EA –эфферентная артериола; GM-CSF –
              гранулоцитарно-макрофагальный колониестимулирующий фактор; IFNγ –
              γ-интерферон; LPS – липополисахарид; MR – активируемый лигандами
              фактор транскрипции, который повреждает эндотелиальные клетки,
              адипоциты, гладкомышечные клетки и иммунные клетки за пределами
              почек; NO – оксид азота; NF-κB – ядерный фактор каппа-легкой цепи
              – усилитель активированных β-клеток, белковый комплекс; RAGE –
              конечные продукты гликирования, связанные с рецепторами; TNF-a
              –фактор некроза опухоли-а.
              <br />
              Адаптировано из: Naaman SC, Bakris GL. Diabetic Nephropathy:
              Update on Pillars of Therapy Slowing Progression. Diabetes Care.
              2023 Sep 1;46(9):1574-1586. doi: 10.2337/dci23-0030.
            </div>

            <div class="fs-18 mb-8">
              <b class="fw-b" style="color: #3e1876"
                >Диабетическая нефропатия (ДН)</b
              >
              развивается у 30-40 % больных сахарным диабетом (СД) 1 и 2 типа.
              Это ведущая причина терминальной стадии почечной недостаточности
              (тПН) в мире. Доля людей с ДНП в структуре пациентов, получающих
              лечение заместительной почечной терапии, достигает
              40%<sup>15</sup>.
            </div>
            <div class="fs-18 fw-sb mb-4">ДН характеризуется<sup>1</sup>:</div>
            <div class="d-flex mb-2">
              <div class="mr-4 mt-1" style="flex-shrink: 0">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                  style="width: 20px"
                />
              </div>
              <div class="fs-18 ff-r">
                Стойкой альбуминурией 30-300 мг/сут. (умеренная альбуминурия),
                либо &#62;300 мг/сут. (явная протеинурия), измеряемой по крайней
                мере дважды с интервалом от 3 до 6 месяцев
              </div>
            </div>
            <div class="d-flex mb-8">
              <div class="mr-4 mt-1" style="flex-shrink: 0">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                  style="width: 20px"
                />
              </div>
              <div class="fs-18 ff-r">
                Прогрессирующим снижением СКФ, которое часто сопровождается
                повышением артериального давления
              </div>
            </div>
            <input type="checkbox" id="second-block_tab2" class="d-none" />
            <label
              for="second-block_tab2"
              class="second-block__tab2-show button button_pink-light mx-auto mt-4 px-10 py-4 fs-18"
            >
              Узнать больше
            </label>
            <label
              @click="secondBlockTab2Hide()"
              for="second-block_tab2"
              class="second-block__tab2-hide button button_empty-pink-light mx-auto mt-4 px-10 py-4 fs-18"
            >
              Скрыть
            </label>
            <div class="second-block__tab2-hidden mt-15 mb-10">
              <div class="d-xl-flex mb-20">
                <div class="mr-4 mr-md-0">
                  <div class="fs-23 ff-rs fw-m mb-5">
                    Целевые уровни АД у пациентов с артериальной гипертензией и
                    сахарным диабетом согласно клиническим рекомендациям 2024г.
                    по лечению АГ у взрослых
                  </div>
                  <div
                    class="px-6 py-9 mb-md-5"
                    style="
                      border-radius: 24px;
                      border: 1px solid #d2d2d2;
                      background-color: #fff;
                    "
                  >
                    <div class="fs-23 ff-rs fw-m mb-5" style="color: #3e1876">
                      Пациентам с АГ в сочетании с СД, получающим
                      антигипертензивную терапию, в связи с доказанными
                      преимуществами в снижении СС риска рекомендуется:
                    </div>
                    <div class="second-block__line second-block__line_violet">
                      <div class="second-block__line-item">
                        <div class="second-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                          />
                        </div>
                        <div class="second-block__line-item-text my-auto">
                          <div class="second-block__line-item-text">
                            В качестве целевого уровня снижать САД до значений
                            130 мм рт. ст. и ниже при хорошей переносимости, но
                            не ниже 120 мм рт. ст.
                          </div>
                        </div>
                      </div>
                      <div class="second-block__line-item">
                        <div class="second-block__line-item-index">
                          <img
                            :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/check_violet.svg`"
                          />
                        </div>
                        <div class="second-block__line-item-text my-auto">
                          <div class="second-block__line-item-text">
                            Целевой уровень ДАД у всех пациентов с АГ и СД
                            составляет &lt;80 мм рт. ст., но не ниже 70 мм рт.
                            ст.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mr-4 mr-md-0"
                  style="flex-basis: 354px; flex-shrink: 0"
                >
                  <picture>
                    <source
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-2.jpg 2x`"
                      media="(max-width: 767px)"
                    />
                    <source
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-2_tab.jpg 2x`"
                      media="(max-width: 1219px)"
                    />
                    <source
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-2.jpg 2x`"
                      media="(min-width: 1220px)"
                    />
                    <img
                      style="
                        height: 100%;
                        object-fit: cover;
                        border-radius: 20px;
                        width: 100%;
                      "
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-2.jpg 2x`"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div class="fs-28 ff-rs mt-20 fw-b mb-8" style="text-align: center">
              SCORE2-Diabetes: оценка 10-летнего сердечно-сосудистого риска при
              сахарном диабете 2 типа в Европе<sup>16</sup>
            </div>
            <div
              class="fs-23 ff-rs fw-m mb-8"
              style="color: #3e1876; text-align: center"
            >
              Процесс разработки
            </div>
            <div class="second-block__tab2-grid mb-10">
              <div
                class="second-block__tab2-grid-item second-block__tab2-grid-item1"
              >
                <div
                  class="px-6 py-8 mb-md-5"
                  style="
                    border-radius: 24px;
                    border: 1px solid #d2d2d2;
                    background-color: #fff;
                    box-shadow: 0px 40px 50px -30px #00000059;
                    height: 100%;
                  "
                >
                  <img
                    class="mx-auto mb-5"
                    style=""
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-grid-item1.svg`"
                  />
                  <div class="fs-18 fw-b mb-5" style="text-align: center">
                    Исходные алгоритмы SCORE2
                  </div>
                  <div class="fs-16">
                    <ul style="list-style-type: disc; margin-left: 0.75em">
                      <li class="mb-3" style="color: #3e1876">
                        <span style="color: #1f1f1f"
                          >Прогностические факторы: возраст, пол, курение,
                          сахарный диабет, САД, уровень общего холестерина и
                          ХС-ЛПВП.</span
                        >
                      </li>
                      <li class="mb-3" style="color: #3e1876">
                        <span style="color: #1f1f1f">
                          Калибровка для прогнозирования риска ССЗ в регионах
                          Европы с
                          <b class="fw-b" style="color: #68d2df">низким</b>,
                          <b class="fw-b" style="color: #de823c">умеренным</b>,
                          <b class="fw-b" style="color: #cf1517">высоким</b> и
                          <b class="fw-b" style="color: #910f10"
                            >очень высоким</b
                          >
                          риском.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="second-block__tab2-grid-item second-block__tab2-grid-item2"
              >
                <div
                  class="px-6 py-8 mb-md-5"
                  style="
                    border-radius: 24px;
                    border: 1px solid #d2d2d2;
                    background-color: #fff;
                    box-shadow: 0px 40px 50px -30px #00000059;
                    height: 100%;
                  "
                >
                  <img
                    class="mx-auto mb-5"
                    style=""
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-grid-item2.svg`"
                  />
                  <div class="fs-18 fw-b mb-5" style="text-align: center">
                    Адаптация модели SCORE2 для пациентов с СД 2-го типа
                  </div>
                  <div class="fs-16" style="text-align: center">
                    Следующие прогностические факторы: возраст на момент
                    постановки диагноза СД, уровень HbA1c и рСКФ
                    <span class="fw-b my-2 d-block" style="color: #3e1876"
                      >→ SCORE2-Diabetes</span
                    >
                    Использованные данные: 229 460 пациентов с СД 2-го типа,
                    взятые из электронных медицинских карт, реестра по сахарному
                    диабету, когортных исследований.
                  </div>
                </div>
              </div>
              <div
                class="second-block__tab2-grid-item second-block__tab2-grid-item3"
              >
                <div
                  class="px-6 py-8 mb-md-5"
                  style="
                    border-radius: 24px;
                    border: 1px solid #d2d2d2;
                    background-color: #fff;
                    box-shadow: 0px 40px 50px -30px #00000059;
                    height: 100%;
                  "
                >
                  <img
                    class="mx-auto mb-5"
                    style=""
                    :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-grid-item3.svg`"
                  />
                  <div class="fs-18 fw-b mb-5" style="text-align: center">
                    Валидация модели SCORE2-Diabetes
                  </div>
                  <div class="fs-16" style="text-align: center">
                    Внешняя валидация на данных 217 036 пациентов с СД 2-го типа
                    из Швеции, Испании, Мальты и Хорватии.
                  </div>
                </div>
              </div>
            </div>
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-3_mob.jpg 2x`"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-3.jpg 2x`"
                media="(max-width: 1219px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-3.jpg 2x`"
                media="(min-width: 1220px)"
              />
              <img
                class="mx-auto mb-15"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-3.jpg 2x`"
              />
            </picture>
            <div class="fs-23 ff-rs fw-m mb-8" style="color: #3e1876">
              Основные особенности
            </div>
            <div class="d-xl-flex mb-15">
              <div class="mr-4 mr-sm-0" style="flex-basis: 40%; flex-shrink: 0">
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      style="width: 30px"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Позволяет оценить 10-летний риск сердечно-сосудистых событий
                    у пациентов с СД 2-го типа
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      style="width: 30px"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Позволяет различать уровень риска пациентов с СД 2-го типа,
                    используя стандартные факторы риска ССЗ и факторы,
                    непосредственно связанные с сахарным диабетом
                  </div>
                </div>
              </div>
              <div class="ml-4 ml-sm-0" style="flex-basis: 60%; flex-shrink: 0">
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      style="width: 30px"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Калибровка для прогнозирования риска ССЗ в регионах Европы с
                    <b class="fw-b" style="color: #68d2df">низким</b>,
                    <b class="fw-b" style="color: #de823c">умеренным</b>,
                    <b class="fw-b" style="color: #cf1517">высоким</b> и
                    <b class="fw-b" style="color: #910f10">очень высоким</b>
                    риском.
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      style="width: 30px"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Согласование с прогнозированием риска по модели SCORE2 для
                    людей без СД
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-4 mt-1" style="flex-shrink: 0">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/sd2/check_violet.svg`"
                      style="width: 30px"
                    />
                  </div>
                  <div class="fs-18 ff-r">
                    Отдельные оценки риска для мужчин и для женщин с СД 2-го
                    типа.
                  </div>
                </div>
              </div>
            </div>
            <div class="full-screen mb-5">
              <div
                class="full-screen__trigger d-md-none"
                :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-4.jpg
                      `"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <picture>
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/second-block__tab2-4.jpg 2x
                        `"
                />
              </picture>
            </div>
            <div class="fs-18_s mb-20" style="color: rgb(156, 156, 156)">
              Адаптировано из: Lisa Pennells, Stephen Kaptoge, Helena Bleken
              Оstergaard, Stephanie H Read, Fabrizio Carinci, Massimo Massi
              Benedetti, Naveed Sattar, Frank L.J. Visseren, Sarah Wild,
              Emanuele Di Angelantonio Модель SCORE 2-Diabetes: оценка
              10-летнего сердечно-сосудистого риска при сахарном диабете 2 типа
              в Европе // Кардиология: Новости. Мнения. Обучение. 2023. №3 (34).
              URL:
              https://cyberleninka.ru/article/n/model-score-2-diabetes-otsenka-10-letnego-serdechno-sosudistogo-riska-pri-saharnom-diabete-2-tipa-v-evrope
              (дата обращения: 24.10.2024).
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="third-block">
      <div class="content">
        <div class="d-flex direction-xl-row direction-column-reverse mb-20">
          <div style="flex-basis: 40%; flex-shrink: 0">
            <div
              class="px-6 py-9 mb-4"
              style="
                border-radius: 0px 24px 24px 0px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 40px 50px -30px;
                border-left: 7px solid rgb(208, 0, 111);
                background-color: #f8f8f8;
              "
            >
              <div class="fs-18">
                <b class="fw-b" style="color: #d0006f">Резистентная АГ</b> –
                недостижение целевых значений артериального давления при
                одновременном использовании трех антигипертензивных препаратов
                разных классов, включая диуретик, у пациентов, приверженных к
                терапии<sup>1</sup>.
              </div>
            </div>
            <div
              class="px-6 py-9 mb-4"
              style="
                border-radius: 0px 24px 24px 0px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 40px 50px -30px;
                border-left: 7px solid rgb(208, 0, 111);
                background-color: #f8f8f8;
              "
            >
              <div class="fs-18">
                Истинная резистентная АГ встречается, не более чем в 10% случаев
                среди всей популяции пациентов АГ, однако в отдельных группах
                пациентов, например, с ХБП, ее распространенность может доходить
                до 30%.
              </div>
            </div>
            <div
              class="px-6 py-9"
              style="
                border-radius: 0px 24px 24px 0px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 40px 50px -30px;
                border-left: 7px solid rgb(208, 0, 111);
                background-color: #f8f8f8;
              "
            >
              <div class="fs-18">
                <b class="fw-b" style="color: #d0006f"
                  >Целевое АД при резистентной АГ</b
                >
                соответствует &lt;140/90 мм рт.ст. и &lt;130/80 мм рт.ст. при
                хорошей переносимости1.
              </div>
            </div>
          </div>
          <div style="flex-basis: 60%; flex-shrink: 0" class="my-auto mb-sm-5">
            <div class="overflow">
              <div class="overflow__body">
                <img
                  :srcset="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block1.jpg 2x
                                  `"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="fs-28 ff-rs fw-b mb-10" style="text-align: center">
          Причины резистентной артериальной гипертензии<sup>1</sup>
        </div>
        <div class="third-block__grid mb-5">
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon1.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Образ жизни, включая ожирение или быстрая прибавка веса
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Образ жизни, включая ожирение или быстрая прибавка веса
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon2.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Чрезмерное употребление алкоголя или поваренной соли
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Чрезмерное употребление алкоголя или поваренной соли
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon3.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Стеноз почечных артерий
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Стеноз почечных артерий
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon4.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Первичный альдостеронизм
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Первичный альдостеронизм
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon5.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">Синдром Кушинга</div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Синдром Кушинга
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon6.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Синдром обструктивного апноэ сна (СОАС)
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Синдром обструктивного апноэ сна (СОАС)
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon7.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Выраженное ПОМ, особенно ХБП или повышенная жесткость крупных
                  артерий
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Выраженное ПОМ, особенно ХБП или повышенная жесткость крупных
                  артерий
                </div>
              </div>
            </div>
          </div>
          <div class="third-block__grid-item">
            <div class="d-flex direction-column direction-xl-row">
              <div
                style="flex-basis: 115px; flex-shrink: 0"
                class="mr-8 mr-md-0 mb-md-4"
              >
                <img
                  class="mx-auto"
                  :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__icon8.jpg 2x
                        `"
                />
              </div>
              <div class="my-auto">
                <div class="fs-18 d-none d-xl-block">
                  Применение кардиотонических средств, кроме сердечных
                  гликозидов (вазопрессоров) или веществ, способствующих
                  задержке натрия, препаратов, назначенных по поводу
                  сопутствующих заболеваний, растительных препаратов,
                  употребление наркотиков (кокаин и т.п.) или анаболических
                  стероидов
                </div>
                <div class="fs-18 d-xl-none" style="text-align: center">
                  Применение кардиотонических средств, кроме сердечных
                  гликозидов (вазопрессоров) или веществ, способствующих
                  задержке натрия, препаратов, назначенных по поводу
                  сопутствующих заболеваний, растительных препаратов,
                  употребление наркотиков (кокаин и т.п.) или анаболических
                  стероидов
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fs-18_s mb-20" style="color: rgb(156, 156, 156)">
          Примечание: ПОМ – поражение органов-мишеней; ХБП – хроническая болезнь
          почек.
        </div>
        <div class="third-block__dropdown mb-8">
          <input type="checkbox" id="third-block__dropdown1" />
          <label
            for="third-block__dropdown1"
            class="third-block__dropdown-head"
          >
            <div class="fs-23 ff-rs">
              Подробнее о причинах псевдорезистентности<sup>1</sup>
            </div>
            <div class="third-block__dropdown-arrow">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 8.25L12.75 15.75L5.25 8.25"
                  stroke="#3C4242"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </label>
          <div class="third-block__dropdown-body">
            <div class="fs-18">
              <ul style="list-style-type: disc; margin-left: 0.75em">
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Низкая приверженность к назначенной терапии является частой
                    причиной псевдорезистентности, она выявляется у ≤50%
                    пациентов, которых обследовали с помощью методов прямого
                    мониторинга контроля приема препаратов, этот показатель
                    прямо зависит от количества назначенных таблеток;</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Феномен «белого халата» (при котором АД, измеренное в
                    медицинском учреждении, повышено, но по данным СМАД или ДМАД
                    уровень АД контролируется) встречается нередко, в связи с
                    чем рекомендуется подтвердить наличие АГ с помощью СМАД или
                    ДМАД;</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Нарушение правил измерения АД в медицинском
                    учреждении;</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Выраженный кальциноз плечевой артерии, особенно у пожилых
                    пациентов;</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Клиническая инертность, приводящая к назначению
                    нерациональных комбинаций и неадекватных доз
                    антигипертензивных препаратов.</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="third-block__dropdown mb-20">
          <input type="checkbox" id="third-block__dropdown2" />
          <label
            for="third-block__dropdown2"
            class="third-block__dropdown-head"
          >
            <div class="fs-23 ff-rs">
              Лечение артериальной гипертензии у взрослых<sup>1</sup>
            </div>
            <div class="third-block__dropdown-arrow">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 8.25L12.75 15.75L5.25 8.25"
                  stroke="#3C4242"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </label>
          <div class="third-block__dropdown-body">
            <div class="fs-18 mb-4">
              Для достижение целевого уровня АД важное значение имеет как
              оптимальная медикаментозная антигипертензивная терапия, так и
              немедикаментозные методы контроля, которые усиливают эффективность
              фармакологического лечения<sup>1</sup>.
              <br />
              <br />
              На сегодняшний день старт антигипертензивной терапии показан при
              уровне АД ≥140/90 мм рт.ст. (офисное или внеофисное) у пациентов
              18-79 лет; у пациентов 80 лет и старше – при АД ≥160/90 мм рт.ст.
            </div>
            <div class="fs-18 fw-sb ff-rs mb-4" style="color: #d0006f">
              В практической деятельности врача используются 5 основных классов
              антигипертензивных препаратов:
            </div>
            <div class="fs-18">
              <ul
                class="mb-4"
                style="list-style-type: disc; margin-left: 0.75em"
              >
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Ингибиторы ангиотензинпревращающего фермента (иАПФ);</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Блокаторы рецепторов ангиотензина-II (БРА);</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f">
                    Тиазидные (гидрохлортиазид) / тиазидоподобные диуретики
                    (хлорталидон, индапамид);</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f">Антагонисты кальция (АК);</span>
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >В некоторых случаях – бета-блокаторы (ББ);</span
                  >
                </li>
                <li class="mb-3" style="color: #d0006f">
                  <span style="color: #1f1f1f"
                    >Петлевые диуретики применяются при снижении рСКФ &lt;30
                    мл/мин/1.73м&#178;.</span
                  >
                </li>
              </ul>
              <div class="fs-18 mb-4">
                Согласно действующим Рекомендациям РКО всем пациентам (за
                исключением пациентов низкого риска с АД &lt;150/90 мм рт.ст.;
                пациентов 80 лет и старше; пациентов с синдромом старческой
                астении) в качестве стартовой терапии назначается комбинация
                антигипертензивных препаратов в оптимальных терапевтических
                дозах. Преимуществом комбинированной терапии является более
                выраженное снижение АД в сравнении с монотерапией, а также
                лучшая переносимость<sup>1</sup>.
              </div>
              <div class="fs-18 fw-sb ff-rs mb-4" style="color: #d0006f">
                Если целевой уровень АД на фоне комбинированной терапии не
                достигнут, следующим шагом становится<sup>1</sup>:
              </div>
              <div class="fs-18">
                <ul
                  class="mb-4"
                  style="list-style-type: disc; margin-left: 0.75em"
                >
                  <li class="mb-3" style="color: #d0006f">
                    <span style="color: #1f1f1f"
                      >Использование более высоких дозировок в данной
                      комбинации;</span
                    >
                  </li>
                  <li class="mb-3" style="color: #d0006f">
                    <span style="color: #1f1f1f"
                      >Или переход на альтернативную 2-х компонентную
                      комбинацию;</span
                    >
                  </li>
                  <li class="mb-3" style="color: #d0006f">
                    <span style="color: #1f1f1f">
                      Или сразу переход на 3-х компонентную схему лечения;</span
                    >
                  </li>
                  <li class="mb-3" style="color: #d0006f">
                    <span style="color: #1f1f1f"
                      >В качестве основных препаратов, которые рекомендуется
                      добавлять к тройной комбинации при резистентной АГ,
                      рассматриваются:
                      <br />

                      • спиронолактон;<br />
                      • или бета-блокаторы;<br />
                      • или альфа1-адрено-блокаторы;<br />
                      • или препараты центрального действия (клонидин).
                    </span>
                  </li>
                </ul>
              </div>
              <div class="fs-18">
                Денервация почечных артерий может быть рекомендована как
                дополнительный вариант лечения у пациентов с резистентной АГ
                (если рСКФ &#62;40 мл/мин/1.73м&#178;).
              </div>
            </div>
          </div>
        </div>
        <div class="fs-28 ff-rs fw-b mb-8" style="text-align: center">
          Тактика ведения пациентов с повышением артериального давления<sup
            >1</sup
          >
        </div>
        <div class="full-screen mb-5">
          <div
            class="full-screen__trigger d-md-none"
            :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block2.jpg
                      `"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="8" fill="#830051"></rect>
              <path
                d="M23 11H29V17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 29H11V23"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M29 11L22 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M11 29L18 22"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <picture>
            <img
              class="mx-auto"
              :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block2.jpg 2x
                        `"
            />
          </picture>
        </div>
        <div class="fs-18_s mb-20" style="color: #9c9c9c">
          Сокращения: АГ — артериальная гипертензия; АД — артериальное давление;
          ПОМ — поражение органов-мишеней; ССО — сердечно-сосудистые осложнения.
        </div>

        <div class="fs-28 ff-rs fw-b mb-8" style="text-align: center">
          Алгоритм стандартного лечения артериальной гипертензии I-II стадий<sup
            >1</sup
          >
        </div>
        <div class="full-screen mb-5">
          <div
            class="full-screen__trigger d-md-none"
            :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block3.jpg
                      `"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="8" fill="#830051"></rect>
              <path
                d="M23 11H29V17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 29H11V23"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M29 11L22 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M11 29L18 22"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <picture>
            <img
              class="mx-auto"
              :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block3.jpg 2x
                        `"
            />
          </picture>
        </div>
        <div class="fs-18_s mb-20" style="color: #9c9c9c">
          Сокращения: АГ — артериальная гипертензия; АД — артериальное давление;
          АК — антагонисты кальция; ББ — бета-адреноблокаторы; БРА — блокаторы
          рецепторов ангиотензина; ДАД — диастолическое артериальное давление;
          иАПФ — ингибиторы ангиотензинпревращающего фермента; САД —
          систолическое артериальное давление; ССО — сердечно-сосудистые
          осложнения.
        </div>
        <div class="fs-28 ff-rs fw-b mb-8" style="text-align: center">
          Алгоритм лечения резистентной АГ на современном этапе<sup>1</sup>
        </div>
        <div class="full-screen mb-5">
          <div
            class="full-screen__trigger d-md-none"
            :data-coolbox="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block4.jpg
                      `"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="8" fill="#830051"></rect>
              <path
                d="M23 11H29V17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 29H11V23"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M29 11L22 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M11 29L18 22"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <picture>
            <img
              class="mx-auto"
              :srcset="`
                          https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block4.jpg 2x
                        `"
            />
          </picture>
        </div>
        <div class="fs-18_s mb-15" style="color: #9c9c9c">
          Примечание: &#185; – выбор диуретиков при СКФ &#62;45 мл/мин/1.73м2
          тиазидные или тиазидоподбные, при СКФ 30 – 45 мл/мин/1.73м2
          рассмотреть замену на петлевой диуретик, при СКФ &lt;30 мл/мин/1.73м2
          – петлевой диуретик.
          <br />
          &#178; – АМКР противопоказаны.
          <br />
          &#179; – с осторожностью при СКФ &lt;45 мл/мин/1.73м2 и значениях
          калия сыворотки крови &#62;4,5 ммоль/л.
          <br />
          Сокращения: АК – антагонисты кальция; ББ – бета-адреноблокаторы; БРА –
          блокаторы рецепторов ангиотензина II; иАПФ – ингибиторы
          ангиотензинпревращающего фермента; рСКФ – расчётная скорость
          клубочковой фильтрации; Т – тиазидные диуретики; ТП – тиазидоподобные
          диуретики. Адаптировано из: Кобалава Ж. Д. и др. Артериальная
          гипертензия у взрослых. Клинические рекомендации 2024 //Российский
          кардиологический журнал. – 2024. – Т. 29. – №. 9. – С. 6117.
        </div>
        <div class="fs-18 fw-b mb-20">
          <span style="color: #d0006f">Важно!</span> По данным мета-анализа
          установлено, что у пациентов с ХБП и альбуминурией применение
          спиронолактона в сочетании с иАПФ / БРА связано с двукратным
          увеличением частоты гиперкалиемии и увеличением в 5 раз риска
          гинекомастии. Таким образом, гиперкалиемия действует как барьер и
          ограничивает широкое использование спиронолактона<sup>17</sup>.
        </div>
        <div class="fs-28 ff-rs fw-b mb-8" style="text-align: center">
          Осложнения артериальной гипертензии и поражение органов-мишеней<sup
            >18</sup
          >
        </div>
        <div class="tap-hint mb-4 mb-md-8 mt-4">
          <div class="tap-hint__icon ml-auto mr-2">
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tap.svg`"
            />
          </div>
          <div class="tap-hint__text">
            Нажмите «<span class="fw-b">+</span>», чтобы узнать больше
          </div>
        </div>
        <div class="third-block__modals d-none d-xl-block">
          <div class="third-block__modals-row">
            <div class="third-block__modals-column">
              <div class="third-block__modals-block">
                <div class="third-block__modals-block-head">
                  <div class="third-block__modals-block-icon">
                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon1.png 2x`"
                    />
                  </div>
                  <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                    Глаза
                  </div>
                  <div
                    class="third-block__modals-block-plus"
                    @click="thirdBlockModalToggle(true, 0)"
                  ></div>
                </div>
                <div class="third-block__modals-block-text fs-18 mt-5">
                  • Ретинопатия
                  <br />
                  • Снижение остроты зрения
                </div>
              </div>
              <div class="third-block__modals-block">
                <div class="third-block__modals-block-head">
                  <div class="third-block__modals-block-icon">
                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon2.png 2x`"
                    />
                  </div>
                  <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                    Почки
                  </div>
                  <div
                    class="third-block__modals-block-plus"
                    @click="thirdBlockModalToggle(true, 1)"
                  ></div>
                </div>
                <div class="third-block__modals-block-text fs-18 mt-5">
                  • Микроальбуминурия
                  <br />
                  • Хроническая болезнь почек
                </div>
              </div>
              <div class="third-block__modals-block">
                <div class="third-block__modals-block-head">
                  <div class="third-block__modals-block-icon">
                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon3.png 2x`"
                    />
                  </div>
                  <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                    Сосуды
                  </div>
                  <div
                    class="third-block__modals-block-plus"
                    @click="thirdBlockModalToggle(true, 2)"
                  ></div>
                </div>
                <div class="third-block__modals-block-text fs-18 mt-5">
                  • Атеросклероз
                  <br />
                  • Артериолосклероз
                  <br />
                  • Аневризмы
                </div>
              </div>
            </div>
            <div class="third-block__modals-column">
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block5.jpg 2x`"
              />
            </div>
            <div class="third-block__modals-column">
              <div class="third-block__modals-block">
                <div class="third-block__modals-block-head">
                  <div class="third-block__modals-block-icon">
                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon4.png 2x`"
                    />
                  </div>
                  <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                    Головной мозг
                  </div>
                  <div
                    class="third-block__modals-block-plus"
                    @click="thirdBlockModalToggle(true, 3)"
                  ></div>
                </div>
                <div class="third-block__modals-block-text fs-18 mt-5">
                  • Инсульт
                  <br />
                  • Когнитивные нарушения, спутанность сознания, деменция
                  <br />
                  • Головная боль
                  <br />
                  • Судороги
                </div>
              </div>
              <div class="third-block__modals-block">
                <div class="third-block__modals-block-head">
                  <div class="third-block__modals-block-icon">
                    <img
                      :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon5.png 2x`"
                    />
                  </div>
                  <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                    Сердце
                  </div>
                  <div
                    class="third-block__modals-block-plus"
                    @click="thirdBlockModalToggle(true, 4)"
                  ></div>
                </div>
                <div class="third-block__modals-block-text fs-18 mt-5">
                  • Острый инфаркт миокарда<br />
                  • Гипертрофия миокарда<br />
                  • Сердечная недостаточность<br />
                  • Нарушение ритма
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="third-block__modals d-xl-none">
          <div class="third-block__modals-row">
            <div class="third-block__modals-block">
              <div class="third-block__modals-block-head">
                <div class="third-block__modals-block-icon">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon3.png 2x`"
                  />
                </div>
                <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                  Сосуды
                </div>
                <div
                  class="third-block__modals-block-plus"
                  @click="thirdBlockModalToggle(true, 2)"
                ></div>
              </div>
              <div class="third-block__modals-block-text fs-18 mt-5">
                • Атеросклероз
                <br />
                • Артериолосклероз
                <br />
                • Аневризмы
              </div>
            </div>
            <div class="third-block__modals-block">
              <div class="third-block__modals-block-head">
                <div class="third-block__modals-block-icon">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon2.png 2x`"
                  />
                </div>
                <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                  Почки
                </div>
                <div
                  class="third-block__modals-block-plus"
                  @click="thirdBlockModalToggle(true, 1)"
                ></div>
              </div>
              <div class="third-block__modals-block-text fs-18 mt-5">
                • Микроальбуминурия
                <br />
                • Хроническая болезнь почек
              </div>
            </div>
            <div class="third-block__modals-block">
              <div class="third-block__modals-block-head">
                <div class="third-block__modals-block-icon">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon1.png 2x`"
                  />
                </div>
                <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                  Глаза
                </div>
                <div
                  class="third-block__modals-block-plus"
                  @click="thirdBlockModalToggle(true, 0)"
                ></div>
              </div>
              <div class="third-block__modals-block-text fs-18 mt-5">
                • Ретинопатия
                <br />
                • Снижение остроты зрения
              </div>
            </div>

            <div class="third-block__modals-img-container d-none d-md-block">
              <img
                class="third-block__modals-img"
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block5.jpg 2x`"
              />
            </div>
            <div class="third-block__modals-block third-block__modals-block4">
              <div class="third-block__modals-block-head">
                <div class="third-block__modals-block-icon">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon5.png 2x`"
                  />
                </div>
                <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                  Сердце
                </div>
                <div
                  class="third-block__modals-block-plus"
                  @click="thirdBlockModalToggle(true, 4)"
                ></div>
              </div>
              <div class="third-block__modals-block-text fs-18 mt-5">
                • Острый инфаркт миокарда<br />
                • Гипертрофия миокарда<br />
                • Сердечная недостаточность<br />
                • Нарушение ритма
              </div>
            </div>

            <div class="third-block__modals-block third-block__modals-block5">
              <div class="third-block__modals-block-head">
                <div class="third-block__modals-block-icon">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/third-block__modal-icon4.png 2x`"
                  />
                </div>
                <div class="third-block__modals-block-name fs-18 ff-rs fw-sb">
                  Головной мозг
                </div>
                <div
                  class="third-block__modals-block-plus"
                  @click="thirdBlockModalToggle(true, 3)"
                ></div>
              </div>
              <div class="third-block__modals-block-text fs-18 mt-5">
                • Инсульт
                <br />
                • Когнитивные нарушения, спутанность сознания, деменция
                <br />
                • Головная боль
                <br />
                • Судороги
              </div>
            </div>
          </div>
        </div>
        <PopUpDefault
          class="third-block__modal"
          @close="thirdBlockModalToggle(false)"
          v-if="thirdBlockModal"
        >
          <div class="third-block__modal-icon">
            <img
              class="mx-auto"
              :srcset="`https://storage.yandexcloud.net/${
                $root.backetName
              }/media/content/public/assets/img/ag/third-block__modal-icon${
                thirdBlockCurrentModal + 1
              }.png 2x`"
            />
          </div>
          <div
            class="third-block__modal-text mt-10 fs-18"
            style="text-align: center"
            v-html="modals[thirdBlockCurrentModal].text"
          ></div>
        </PopUpDefault>
      </div>
    </section>

    <section class="fourth-block" id="clin-cases">
      <div class="content">
        <div class="d-md-flex">
          <div>
            <div class="fourth-block__title">
              Клинические <br class="d-none d-xl-block" />примеры*
            </div>
          </div>
          <div class="ml-auto mt-xs-4 mb-xs-16">
            <a href="#" class="button button_empty-pink-light py-4 px-8">
              Больше клинических ситуаций
            </a>
          </div>
        </div>

        <div class="tap-hint mb-4 mt-4 d-none d-md-flex">
          <div class="tap-hint__icon ml-auto mr-2">
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/tap.svg`"
            />
          </div>
          <div class="tap-hint__text">Выберите клинический пример</div>
        </div>
      </div>
      <div class="fixed-tabs">
        <div class="content">
          <div class="fourth-block__tabs">
            <div
              class="fourth-block__arrow fourth-block__arrow_left d-xl-none"
              @click="
                choiceFourthTab(
                  fourthTabsActive > 0 ? fourthTabsActive - 1 : fourthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 4.5L3.75 12L11.25 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12L20.25 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>

            <div class="fourth-block__tabs-row">
              <div
                class="fourth-block__tab"
                @click="choiceFourthTab(0)"
                :class="{
                  'fourth-block__tab_active': fourthTabsActive === 0,
                }"
              >
                <div class="fourth-block__tab-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/fourth-block__child1.jpg
                    `"
                  />
                </div>
                <div class="fourth-block__tab-text">
                  Пациентка В., 62&nbsp;года
                </div>
              </div>
              <div
                class="fourth-block__tab"
                @click="choiceFourthTab(1)"
                :class="{
                  'fourth-block__tab_active fourth-block__tab_violet':
                    fourthTabsActive === 1,
                }"
              >
                <div class="fourth-block__tab-icon">
                  <img
                    :src="`
                      https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/fourth-block__child2.jpg
                    `"
                  />
                </div>
                <div class="fourth-block__tab-text">
                  Пациент А., 72&nbsp;года
                </div>
              </div>
            </div>

            <div
              class="fourth-block__arrow fourth-block__arrow_right d-xl-none"
              @click="
                choiceFourthTab(
                  fourthTabsActive < 2 ? fourthTabsActive + 1 : fourthTabsActive
                )
              "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="fourth-block__tabs-body">
          <div v-if="fourthTabsActive === 0">
            <div class="fourth-block__case">
              <div class="fourth-block__case-row">
                <div class="fourth-block__case-child-block">
                  <div class="fourth-block__case-child">
                    <img
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/fourth-block__child1.jpg
                      `"
                    />
                  </div>
                  <div class="fourth-block__case-tip">
                    *гипотетические случаи пациентов с артериальной гипертензией
                  </div>
                </div>
                <div class="fourth-block__case-content">
                  <div class="fourth-block__case-tabs">
                    <div class="fourth-block__case-tabs-list">
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(0)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 0,
                        }"
                      >
                        Жалобы и&nbsp;анамнез
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(1)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 1,
                        }"
                      >
                        Физикальный осмотр
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(2)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 2,
                        }"
                      >
                        Лабораторные обследования
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(3)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 3,
                        }"
                      >
                        Инструментальные обследования
                      </div>
                    </div>
                    <div class="fourth-block__case-tabs-arrows">
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab > 0
                              ? casesActiveTab - 1
                              : casesActiveTab
                          )
                        "
                        class="fourth-block__case-tabs-arrow fourth-block__case-tabs-arrow_left"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.25 4.5L3.75 12L11.25 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M5.25 12L20.25 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab < 3
                              ? casesActiveTab + 1
                              : casesActiveTab
                          )
                        "
                        class="fourth-block__case-tabs-arrow fourth-block__case-tabs-arrow_right"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 4.5L20.25 12L12.75 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M18.75 12L3.75 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="fourth-block__case-tabs-body">
                    <div v-if="casesActiveTab === 0">
                      <div class="fs-18">
                        На консультацию к кардиологу обратилась пациентка В., 62
                        года с жалобами на головные боли в затылочной и височной
                        областях в течение последних нескольких месяцев, при
                        обследовании (СМАД), было выявлено, что данные жалобы
                        возникают при повышении уровня АД=160-190/100-110 мм
                        рт.ст.
                        <br />
                        <br />
                        Впервые гипертоническая болезнь диагностирована 7 лет
                        назад. Максимальное АД – 240/130 мм рт. ст. Привычное АД
                        – 150-160/110 мм рт.ст. На постоянной основе в течение
                        последних 2 лет принимает следующие препараты для
                        контроля уровня АД:
                        <br />
                        <br />
                        • Валсартан 160 мг / Гидрохлортиазид 25 мг х 1 р/д
                        утром; <br />
                        • Валсартан 160 мг х 1 р/д вечером; <br />
                        • Амлодипин 10 мг вечером; <br />
                        • Бисопролол 5 мг утром.
                        <br />
                        <br />
                        На фоне регулярного приема антигипертензивной терапии
                        целевой уровень АД не достигнут. Цифры АД на постоянной
                        основе 170-180/110 мм рт.ст.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 1">
                      <div class="fs-18">
                        <b class="fw-b">Общее</b> состояние: удовлетворительное.
                        <br />
                        <br />
                        <b class="fw-b">Питание:</b> повышенное.
                        <br />
                        <br />
                        <b class="fw-b">Кожные покровы:</b> обычной окраски и
                        влажности.
                        <br />
                        <br />
                        <b class="fw-b">Носоглотка:</b> Носовое дыхание
                        свободное, зев не гиперемирован, миндалины не увеличены.
                        <br />
                        <br />
                        <b class="fw-b">Подкожная жировая клетчатка:</b>
                        Подкожная жировая клетчатка развита избыточно.
                        <br />
                        <br />
                        <b class="fw-b">Сознание:</b> Сознание ясное, положение
                        активное.
                        <br />
                        <br />
                        <b class="fw-b">Щитовидная железа:</b> Щитовидная железа
                        не увеличена.
                        <br />
                        <br />
                        <b class="fw-b">Лимфатические узлы:</b> Шейные,
                        подчелюстные, надключичные, подключичные, подмышечные,
                        паховые лимфоузлы не увеличены.
                        <br />
                        <br />
                        <b class="fw-b">Костно-мышечная система:</b> Сила мышц
                        достаточная, костная система не изменена, ограничения
                        подвижности суставов нет.
                        <br />
                        <br />
                        <b class="fw-b">Периферические отеки:</b> Периферических
                        отеков нет.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: #d0006f"
                        >
                          Сердечно-сосудистая система
                        </div>
                        ЧСС: <b class="fw-b">58</b> уд./мин. Пульс:
                        <b class="fw-b">58</b> уд./мин. Дефицит пульса:
                        <b class="fw-b">0</b>
                        <br />
                        <b class="fw-b">АД</b> на правой руке:
                        <b class="fw-b">185/110</b> мм рт.ст.
                        <b class="fw-b">АД</b> на левой руке:
                        <b class="fw-b">180/110</b> мм рт.ст.
                        <br />
                        <b class="fw-b"
                          >Осмотр области сердца и периферических сосудов:</b
                        >
                        Область сердца не изменена. <br />
                        <b class="fw-b">Пальпация, перкуссия:</b> Перкуссия
                        сердца: без особенностей. <br />
                        <b class="fw-b">Аускультация: </b> тоны приглушены,
                        ритмичные.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: #d0006f"
                        >
                          Система органов дыхания
                        </div>
                        ЧДД: <b class="fw-b">16</b> дых./мин.
                        <br />
                        <b class="fw-b">Перкуссия легких:</b> Перкуссия легких:
                        без особенностей.
                        <br />
                        <b class="fw-b">Аускультация легких:</b> Дыхание с
                        жестким оттенком, хрипы не выслушиваются.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: #d0006f"
                        >
                          Пищеварительная система
                        </div>
                        <b class="fw-b">Печень:</b> Печень не увеличена.
                        <br />
                        <b class="fw-b">Пальпация, аускультация живота:</b>
                        Живот мягкий, безболезненный.
                        <br />
                        <b class="fw-b">Селезенка:</b> Селезенка не
                        пальпируется.
                        <br />
                        <b class="fw-b">Стул:</b> Стул регулярный.
                        <br />
                        <b class="fw-b">Мочеиспускание:</b> Дизурических явлений
                        нет.
                        <br />
                        <b class="fw-b">Нервная система и органы чувств:</b>
                        сознание ясное.
                        <br />
                        <b class="fw-b">ИМТ</b> – 32,4 (Ожирение 1 степени).
                        <br />
                        <b class="fw-b">Измерена температура тела:</b> 36,6 С.
                      </div>
                    </div>

                    <div v-if="casesActiveTab === 2">
                      <div class="fs-18 mb-5">
                        Выберите методы первичного обследования в больнице при
                        поступлении и проанализируйте результаты.
                      </div>
                      <form>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_pink"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-1"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-1"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Клинический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_1-1.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_pink"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-2"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-2"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Биохимический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_1-2.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_pink"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-1-3"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-1-3"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Общий анализ мочи
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_1-3.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div v-if="casesActiveTab === 3">
                      <div class="fs-18">
                        Электрокардиография: ритм – синусовый ритм. Полная
                        блокада правой ножки пучка Гиса. Желудочковых комплексов
                        в 1 минуту: 62. Зубец Р – 0,12 сек; интервал PQ – 0,2
                        сек; комплекс QRS – 0,15 сек; зубец Т – положительный;
                        интервал QT – 0,46 сек. Подъем сегмента ST: нет.
                        Направление электрической оси сердца: резко влево.
                        Гипертрофия левого предсердия: нет. Гипертрофия левого
                        желудочка: признаки. Индекс Соколова-Лайона – 40 мм.
                        Гипертрофия правого желудочка: нет.
                        <br />
                        <br />
                        Эхокардиография: Расширена полость левого предсердия.
                        Гипертрофия миокарда левого желудочка от незначительной
                        к умеренной (МЖП – 1,4 см; ЗС ЛЖ – 1,2 см). Индекс массы
                        миокарда ЛЖ (по формуле ASE) – 51 г/м<sup>2,7</sup>.
                        Сократительная способность миокарда левого желудочка не
                        снижена. ФВ – 62%. Достоверных зон нарушения локальной
                        сократимости не выявлено. Сократительная способность
                        миокарда правого желудочка не снижена. ФИП – 45%.
                        <br />
                        <br />
                        Аортальный клапан - трехстворчатый. Краевое уплотнение
                        створок аортального клапана. <br />Аортальная
                        регургитация 1ст., незначительная по объему.
                        <br />
                        <br />
                        Створки митрального клапана неоднородны по плотности.
                        Митральная регургитация 1ст., незначительная по объему.
                        Трикуспидальная регургитация 1-2ст., незначительная по
                        объему.
                        <br />
                        <br />
                        Расчетное давление в легочной артерии 29 мм рт. ст. В
                        плевральных синусах и перикарде признаков скопления
                        жидкости не выявлено.
                      </div>
                    </div>
                  </div>
                  <div class="ff-rs fs-23 fw-b mt-6" style="color: #d0006f">
                    Вопросы
                  </div>
                  <Test
                    @refreshTest="refreshTest"
                    :key="testKey"
                    :questions="questions[0]"
                    :tips="tips[0]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="fourthTabsActive === 1">
            <div class="fourth-block__case">
              <div class="fourth-block__case-row">
                <div class="fourth-block__case-child-block">
                  <div
                    class="fourth-block__case-child fourth-block__case-child_violet"
                  >
                    <img
                      :src="`
                        https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/fourth-block__child2.jpg
                      `"
                    />
                  </div>
                  <div class="fourth-block__case-tip">
                    *гипотетические случаи пациентов с артериальной гипертензией
                  </div>
                </div>
                <div class="fourth-block__case-content">
                  <div class="fourth-block__case-tabs">
                    <div class="fourth-block__case-tabs-list">
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(0)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 0,
                        }"
                      >
                        Жалобы и&nbsp;анамнез
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(1)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 1,
                        }"
                      >
                        Физикальный осмотр
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(2)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 2,
                        }"
                      >
                        Лабораторные обследования
                      </div>
                      <div
                        class="fourth-block__case-tabs-tab"
                        @click="changeCasesTabs(3)"
                        :class="{
                          'fourth-block__case-tabs-tab_active':
                            casesActiveTab === 3,
                        }"
                      >
                        Инструментальные обследования
                      </div>
                    </div>
                    <div class="fourth-block__case-tabs-arrows">
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab > 0
                              ? casesActiveTab - 1
                              : casesActiveTab
                          )
                        "
                        class="fourth-block__case-tabs-arrow fourth-block__case-tabs-arrow_left"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.25 4.5L3.75 12L11.25 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M5.25 12L20.25 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          changeCasesTabs(
                            casesActiveTab < 3
                              ? casesActiveTab + 1
                              : casesActiveTab
                          )
                        "
                        class="fourth-block__case-tabs-arrow fourth-block__case-tabs-arrow_right"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 4.5L20.25 12L12.75 19.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                          <path
                            d="M18.75 12L3.75 12"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="fourth-block__case-tabs-body">
                    <div v-if="casesActiveTab === 0">
                      <div class="fs-18">
                        На консультацию обратился пациент А., 72 года после
                        выписки из стационара, где проходил лечение по поводу
                        гипертонического криза, осложненного транзиторной
                        ишемической атакой. В течение 1 месяца на фоне
                        регулярного приема антигипертензивной терапии стал
                        отмечать эпизоды повышения уровня АД 210/120 мм рт.ст.
                        <br />
                        <br />
                        Впервые гипертоническая болезнь диагностирована 17 лет
                        назад. Максимальное АД – 210/120 мм рт.ст. Привычное АД
                        – 140/80 мм рт.ст. На постоянной основе принимает
                        следующие препараты для контроля уровня АД:
                        <br />
                        <br />
                        • Азилсартана медоксомил + хлорталидон (40+25 мг) утром;
                        <br />
                        • Фелодипин 10 мг х 1 раза в сутки (вечером);
                        <br />
                        • Ацетилсалициловая кислота 100 мг утром;
                        <br />
                        • Аторвастатин 40 мг вечером;
                        <br />
                        • Омепразол 20 мг утром до еды;
                        <br />
                        • Метформин 500 мг х 2 раза в сутки.
                        <br />
                        <br />
                        Наблюдается у врача-эндокринолога по поводу сахарного
                        диабета 2 типа в течение 7 лет.
                        <br />
                        Направлен на консультацию к терапевту по месту
                        жительства с целью коррекции антигипертензивной терапии.
                      </div>
                    </div>
                    <div v-if="casesActiveTab === 1">
                      <div class="fs-18">
                        <b class="fw-b">Общее состояние:</b> удовлетворительное.
                        <br /><br />
                        <b class="fw-b">Кожные покровы:</b> обычной окраски и
                        влажности. <br /><br />
                        <b class="fw-b">Носоглотка:</b> Носовое дыхание
                        свободное, зев не гиперемирован, миндалины не увеличены.
                        <br /><br />
                        <b class="fw-b">Подкожная жировая клетчатка:</b>
                        Подкожная жировая клетчатка развита избыточно.
                        <br /><br />
                        <b class="fw-b">Сознание:</b> Сознание ясное, положение
                        активное. <br /><br />
                        <b class="fw-b">Щитовидная железа:</b> Щитовидная железа
                        не увеличена. <br /><br />
                        <b class="fw-b">Лимфатические узлы:</b> Шейные,
                        подчелюстные, надключичные, подключичные, подмышечные,
                        паховые лимфоузлы не увеличены. <br /><br />
                        <b class="fw-b">Костно-мышечная система:</b> Сила мышц
                        достаточная, костная система не изменена, ограничения
                        подвижности суставов нет. <br /><br />
                        <b class="fw-b">Периферические отеки:</b> Периферических
                        отеков нет.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: rgb(208, 0, 111)"
                        >
                          Сердечно-сосудистая система
                        </div>
                        ЧСС: <b class="fw-b">74</b> уд./мин. Пульс:
                        <b class="fw-b">74</b> уд./мин. Дефицит пульса:
                        <b class="fw-b">0</b>
                        <br />
                        <b class="fw-b">АД</b> на правой руке:
                        <b class="fw-b">160 / 90</b> мм.рт.ст.
                        <br />
                        <b class="fw-b"
                          >Осмотр области сердца и периферических сосудов:</b
                        >
                        Область сердца не изменена.
                        <br />
                        <b class="fw-b">Пальпация, перкуссия:</b> Перкуссия
                        сердца: без особенностей.
                        <br />
                        <b class="fw-b">Аускультация:</b> тоны приглушены,
                        ритмичные.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: rgb(208, 0, 111)"
                        >
                          Система органов дыхания
                        </div>
                        ЧДД: <b class="fw-b">17</b> дых./мин.
                        <b class="fw-b">Перкуссия легких:</b> Перкуссия легких:
                        без особенностей.
                        <b class="fw-b">Аускультация легких:</b> Дыхание
                        везикулярное, хрипы не выслушиваются.
                        <br />
                        <br />
                        <div
                          class="ff-rs fs-18 fw-sb mb-5"
                          style="color: rgb(208, 0, 111)"
                        >
                          Пищеварительная система
                        </div>
                        <b class="fw-b">Печень:</b> Печень не увеличена.
                        <br /><br />
                        <b class="fw-b">Пальпация, аускультация живота:</b>
                        Живот мягкий, безболезненный. <br /><br />
                        <b class="fw-b">Селезенка:</b> Селезенка не
                        пальпируется. <br /><br />
                        <b class="fw-b">Стул:</b> Стул регулярный. <br /><br />
                        <b class="fw-b">Мочеиспускание:</b> Дизурических явлений
                        нет. <br /><br />
                        <b class="fw-b">Нервная система и органы чувств:</b>
                        сознание ясное. <br /><br />
                        <b class="fw-b">ИМТ</b> – 35,36 <br /><br />
                        <b class="fw-b">Измерена температура тела:</b> 36,4 С.
                      </div>
                    </div>

                    <div v-if="casesActiveTab === 2">
                      <div class="fs-18 mb-5">
                        Выберите методы первичного обследования в больнице при
                        поступлении и проанализируйте результаты.
                      </div>
                      <form>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_violet"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-1"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-1"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Клинический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_2-1.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_violet"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-2"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-2"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Биохимический анализ крови
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_2-2.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fourth-block__dropdown fourth-block__dropdown_violet"
                        >
                          <input
                            type="radio"
                            name="dropdown_fourth-block"
                            id="dropdown_fourth-block-2-3"
                          />
                          <div class="fourth-block__dropdown-head-wrap">
                            <input
                              type="reset"
                              class="fourth-block__dropdown-clear"
                            />
                            <label
                              for="dropdown_fourth-block-2-3"
                              class="fourth-block__dropdown-head"
                            >
                              <div class="fourth-block__dropdown-head-title">
                                Общий анализ мочи
                              </div>
                              <div class="fourth-block__dropdown-head-arrow">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.144 18.2381C11.6104 18.7045 12.3896 18.7045 12.8572 18.2381L23.6457 7.47715C24.1181 7.00479 24.1181 6.23876 23.6457 5.7676C23.1734 5.29524 22.4061 5.29524 21.9338 5.7676L12 15.6737L2.06751 5.7664C1.59396 5.29404 0.82792 5.29404 0.354367 5.7664C-0.117989 6.23876 -0.117989 7.00479 0.354367 7.47595L11.144 18.2381Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            </label>
                          </div>

                          <div class="fourth-block__dropdown-body">
                            <div class="overflow">
                              <div class="overflow__body">
                                <img
                                  :src="`
                                    https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/ag/test-table_2-3.jpg
                                  `"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div v-if="casesActiveTab === 3">
                      <div class="fs-18">
                        <b class="fw-b">Электрокардиография:</b> ритм –
                        синусовый ритм. Желудочковых комплексов в 1 минуту – 75.
                        Зубец Р – 0,1 сек; интервал PQ – 0,16 сек; комплекс QRS
                        – 0,08 сек; зубец Т – положительный; интервал QT – 0,4
                        сек. Подъем сегмента ST: нет. Направление электрической
                        оси сердца: влево. Нарушение внутрижелудочковой
                        проводимости. Корнеальский вольтажный индекс – 32 мм.
                        <br />
                        <br />
                        <b class="fw-b">Эхокардиография:</b> Полости предсердий
                        незначительно расширены. Септальных дефектов не
                        выявлено. Умеренная гипертрофия миокарда ЛЖ. ВОЛЖ
                        свободен. Индекс массы миокарда ЛЖ (по формуле ASE) – 55
                        г/м<sup>2,7</sup>. Глобальная сократительная функция
                        миокарда ЛЖ не нарушена. Нарушений сегментарной
                        сократительной способности не выявлено. ФВ 57%. ФИП ПЖ
                        45%.
                        <br />
                        <br />
                        Аорта не расширена. АоК: трехстворчатый, створки
                        уплотнены. Аортальная регургитация 0-1 степени. МК:
                        створки уплотнены, митральная регургитация 1 степени.
                        ТрК: створки тонкие, трикуспидальная регургитация 1
                        степени. НПВ не расширена, коллабирует на вдохе.
                        <br />
                        <br />
                        Расчетное систолическое давление в ЛА – 28 мм рт.ст.
                        Свободной жидкости в полости перикарда, плевральных
                        полостях не выявлено.
                      </div>
                    </div>
                  </div>
                  <div class="ff-rs fs-23 fw-b mt-6" style="color: #d0006f">
                    Вопросы
                  </div>
                  <Test
                    @refreshTest="refreshTest"
                    :key="testKey"
                    :questions="questions[1]"
                    :tips="tips[1]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sources">
      <div class="content">
        <div class="sources__title">Источники</div>
        <input type="checkbox" id="sources" />
        <ol class="sources__list">
          <li>
            Кобалава Ж.Д., Конради А.О., Недогода С.В., Шляхто Е.В., Арутюнов
            Г.П., Баранова Е.И., Барбараш О.Л., Бобкова Н.В., Бойцов С.А.,
            Бубнова М.Г., Вавилова Т.В., Виллевальде С.В., Галявич А.С., Глезер
            М.Г., Гринева Е.Н., Гринштейн Ю.И., Драпкина О.М., Жернакова Ю.В.,
            Звартау Н.Э., Иртюга Щ.Б., Кисляк О.А., Козиолова Н.А., Космачева
            Е.Д., Котовская Ю.В., Либис Р.А., Лопатин Ю.М., Небиридзе Д.В.,
            Недошивин А.О., Никулина С.Ю., Остроумова О.Д., Ощепкова Е.В.,
            Ратова Л.Г., Саласюк Ф.С., Скибицкий В.В., Ткачева О.Н., Троицкая
            Е.А., Чазова И.Е., Чесникова Е.И., Чумакова Г.А., Шальнова С.А.,
            Шестакова М.В., Якушин С.С., Янишевский С.Н. Артериальная
            гипертензия у взрослых. Клинические рекомендации 2024. Российский
            кардиологический журнал. 2024;29(9):6117.
            <a
              href="https://doi.org/10.15829/1560-4071-2024-6117"
              target="_blank"
              >https://doi.org/10.15829/1560-4071-2024-6117</a
            >
            .
          </li>
          <li>
            Mills K.T., Bundy J.D., Kelly T.N., et al. Global disparities of
            hypertension prevalence and control: a systematic analysis of
            population-based studies from 90 countries. Circulation.
            2016;134(6):441-50. DOI:10.1161/CIRCULATIONAHA.115.018912.
          </li>
          <li>
            Balanova Yu.A., Shalnova S.A., Imaeva A.E., Kapustina А.V.,
            Muromtseva G.A., Evstifeeva S.V., Tarasov V.I., Redko A.N.,
            Viktorova I.A., Prishchepa N.N., Yakushin S.S., Boytsov S.A.,
            Drapkina O.M. Prevalence, Awareness, Treatment and Control of
            Hypertension in Russian Federation (Data of Observational ESSERF-2
            Study). Rational Pharmacotherapy in Cardiology. 2019;15(4):450-466.
            <a
              href="https://doi.org/10.20996/1819-6446-2019-15-4-450-466"
              target="_blank"
              >https://doi.org/10.20996/1819-6446-2019-15-4-450-466</a
            >
          </li>
          <li>
            SCORE2 working group and ESC Cardiovascular risk collaboration,
            SCORE2 risk prediction algorithms: new models to estimate 10-year
            risk of cardiovascular disease in Europe, European Heart Journal,
            Volume 42, Issue 25, 1 July 2021, Pages 2439–2454,
            <a href="https://doi.org/10.1093/eurheartj/ehab309" target="_blank"
              >https://doi.org/10.1093/eurheartj/ehab309</a
            > 
          </li>
          <li>
            SCORE2-OP working group and ESC Cardiovascular risk collaboration,
            SCORE2-OP risk prediction algorithms: estimating incident
            cardiovascular event risk in older persons in four geographical risk
            regions, European Heart Journal, Volume 42, Issue 25, 1 July 2021,
            Pages 2455–2467,
            <a href="https://doi.org/10.1093/eurheartj/ehab312" target="_blank"
              >https://doi.org/10.1093/eurheartj/ehab312</a
            > 
          </li>
          <li>
            Volpe M., Com J., Coca A., Unger T. The cardiovascular continuum
            refined: A hypothesis. Blood Pressure. 2010;19(5):273–277. doi:
            10.3109/08037051.2010.481072
          </li>
          <li>
            Mancia Chairperson G. et al. 2023 ESH Guidelines for the management
            of arterial hypertension The Task Force for the management of
            arterial hypertension of the European Society of Hypertension
            Endorsed by the European Renal Association (ERA) and the
            International Society of Hypertension (ISH) //Journal of
            hypertension. – 2023. – Т. 41. – №. 12. – С. 1874-2071.
          </li>
          <li>
            Page IH. Pathogenesis of arterial hypertension. J Am Med Assoc
            1949;140:451–8.
          </li>
          <li>
            Awosika A, Khan A, Adabanya U, Omole AE, Millis RM. Aldosterone
            Synthase Inhibitors and Dietary Interventions: A Combined Novel
            Approach for Prevention and Treatment of Cardiovascular Disease.
            Cureus. 2023 Mar 15;15(3):e36184. doi: 10.7759/cureus.36184.
          </li>
          <li>
            Ferreira NS, Tostes RC, Paradis P, Schiffrin EL. Aldosterone,
            Inflammation, Immune System, and Hypertension. Am J Hypertens.
            2021;34(1):15-27. doi:10.1093/ajh/hpaa137
          </li>
          <li>
            Buffolo F, Tetti M, Mulatero P, Monticone S. Aldosterone as a
            Mediator of Cardiovascular Damage. Hypertension.
            2022;79(9):1899-1911. doi:10.1161/HYPERTENSIONAHA.122.17964
          </li>
          <li>
            Zyryanov S.K., Baybulatova E.A. Drug adjustment of modified risk
            factors as one of the leading strategies for managing patients with
            cardiovascular diseases. Meditsinskiy sovet = Medical Council.
            2019;(21):22-38. (In Russ.) doi: 10.21518/2079-701X-2019-21-22-38
          </li>
          <li>
            KDIGO 2024 Clinical Practice Guideline for the Evaluation and
            Management of Chronic Kidney Disease Stevens, Paul E. et al. Kidney
            International, Volume 105, Issue 4, S117 - S314
          </li>
          <li>
            Naaman SC, Bakris GL. Diabetic Nephropathy: Update on Pillars of
            Therapy Slowing Progression. Diabetes Care. 2023 Sep
            1;46(9):1574-1586. doi: 10.2337/dci23-0030.
          </li>
          <li>
            Багрий А.Э., Хоменко М.В., Шверова О.И., Титиевская А.И. Подходы к
            лечению диабетической нефропатии (обзор литературы). Нефрология.
            2021;25(1):18-30.
            <a
              href="https://doi.org/10.36485/1561-6274-2021-25-1-18-30"
              target="_blank"
              >https://doi.org/10.36485/1561-6274-2021-25-1-18-30</a
            >
          </li>
          <li>
            Lisa Pennells, Stephen Kaptoge, Helena Bleken Оstergaard, Stephanie
            H Read, Fabrizio Carinci, Massimo Massi Benedetti, Naveed Sattar,
            Frank L.J. Visseren, Sarah Wild, Emanuele Di Angelantonio Модель
            SCORE 2-Diabetes: оценка 10-летнего сердечно-сосудистого риска при
            сахарном диабете 2 типа в Европе // Кардиология: Новости. Мнения.
            Обучение. 2023. №3 (34).
          </li>
          <li>
            Chung E. Y. M. et al. Aldosterone antagonists in addition to renin
            angiotensin system antagonists for preventing the progression of
            chronic kidney disease //Cochrane Database of Systematic Reviews. –
            2020. – №. 10.
          </li>
          <li>
            Luca CT, Crisan S, Cozma D, Negru A, Lazar MA, Vacarescu C,
            Trofenciuc M, Rachieru C, Craciun LM, Gaita D, Petrescu L, Mischie
            A, Iurciuc S. Arterial Hypertension: Individual Therapeutic
            Approaches-From DNA Sequencing to Gender Differentiation and New
            Therapeutic Targets. Pharmaceutics. 2021 Jun 9;13(6):856. doi:
            10.3390/pharmaceutics13060856. PMID: 34207606; PMCID: PMC8229802.
          </li>
        </ol>
        <label
          for="sources"
          class="sources__button button button_empty-pink-light px-8 py-4 mx-auto mt-10"
        >
        </label>
      </div>
    </section>
  </div>
</template>

<script>
import PagesSlider from "@/components/pageComponents/AG/PagesSlider.vue";
import Test from "@/components/pageComponents/AG/Test.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import "swiper/swiper-bundle.css";
import { bus } from "@/main";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "SD2",
  components: {
    PagesSlider,
    PopUpDefault,
    Swiper,
    SwiperSlide,
    Test,
  },
  data: () => ({
    firstTabsActive: 0,
    thirdBlockModal: false,
    thirdBlockCurrentModal: 0,
    secondTabsActive: 0,
    fourthTabsActive: 0,
    casesActiveTab: 0,

    testKey: 0,
  }),
  computed: {
    topBanners() {
      return [
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner1_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner1_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner1.jpg`,
        },
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner2_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner2_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner2.jpg`,
        },
        {
          banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner3_mob.jpg`,
          banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner3_tab.jpg`,
          banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/ag/banner3.jpg`,
        },
      ];
    },
    questions() {
      return [
        [
          {
            type: "once",
            title:
              "Какой риск сердечно-сосудистых осложнений у данного пациента:",
            answers: ["Риск 1", "Риск 2", "Риск 3", "Риск 4 (очень высокий)"],
          },
          {
            type: "once",
            title: "Установите основной диагноз:",
            answers: [
              "Гипертоническая болезнь II стадии. Целевой уровень АД не достигнут. Резистентная артериальная гипертензия. Риск 4. ГЛЖ. Дислипидемия. Ожирение I степени. ХБП С3а А1",
              "Гипертоническая болезнь III стадии. Целевой уровень АД не достигнут. Риск 3. ГЛЖ. Гипертриглицеридемия. Ожирение I степени. ХБП С3а А1",
              "Гипертоническая болезнь II стадии. Целевой уровень АД не достигнут. Резистентная артериальная гипертензия. Риск 4. ГЛЖ. Дислипидемия. Ожирение I степени. ХБП С3а А2",
            ],
          },
          {
            type: "more",
            title:
              "Какие признаки резистентной АГ присутствуют у пациентки (выберите несколько вариантов):",
            answers: [
              "Лечение с использованием оптимальных (или максимальных переносимых) доз трех и более лекарственных препаратов, включая ингибитор АПФ или БРА, АК и диуретик",
              "Неадекватность контроля АД подтверждена с помощью СМАД",
              "Синдром обструктивного апноэ сна",
              "Пациентка привержена к лечению",
              "Применение сердечных гликозидов",
            ],
          },
          {
            type: "more",
            title:
              "Какие обследования необходимо назначить пациентке (выберите несколько вариантов):",
            answers: [
              "Сцинтиграфия миокарда",
              "Фундоскопия",
              "Холтеровское мониторирование ЭКГ",
              "УЗИ сонных артерий",
              "Консультация нефролога",
              "УЗИ почек + дуплексное сканирование почечных артерий",
            ],
          },
          {
            type: "once",
            title:
              "Какой препарат можно добавить к антигипертензивной терапии в данном случае, учитывая наличие резистентной АГ?",
            answers: [
              "Сакубитрил/валсартан",
              "Дапаглифлозин",
              "Спиронолактон",
              "Эналаприл",
            ],
          },
          {
            type: "once",
            title:
              "Какие риски имеет добавление к терапии препарата спиронолактон в данном случае?",
            answers: [
              "Гиперкалиемия",
              "Гипокалиемия",
              "Присоединение альбуминурии",
            ],
          },
        ],
        [
          {
            type: "once",
            title:
              "Какой риск сердечно-сосудистых осложнений у данного пациента:",
            answers: ["Риск 1", "Риск 2", "Риск 3", "Риск 4 (очень высокий)"],
          },
          {
            type: "once",
            title: "Установите основной диагноз:",
            answers: [
              "Гипертоническая болезнь III стадии. Целевой уровень АД не достигнут. Резистентная артериальная гипертензия. Риск 4. ГЛЖ. Дислипидемия. Сахарный диабет 2 типа, целевой уровень гликированного гемоглобина ≤7,5%. Ожирение II степени. ХБП С3b А2. ТИА",
              "Гипертоническая болезнь II стадии. Целевой уровень АД не достигнут. Риск 4. ГЛЖ. Дислипидемия. Сахарный диабет 2 типа, целевой уровень гликированного гемоглобина ≤7,5%. Ожирение II степени. ХБП С4 А2. ТИА",
              "Гипертоническая болезнь III стадии. Целевой уровень АД не достигнут. Резистентная артериальная гипертензия. Риск 4. ГЛЖ. Дислипидемия. Сахарный диабет 2 типа, целевой уровень гликированного гемоглобина ≤7,5%. ХБП С4 А2",
              "Гипертоническая болезнь III стадии. Целевой уровень АД не достигнут. Резистентная артериальная гипертензия. Риск 4. Дислипидемия. Сахарный диабет 2 типа, целевой уровень гликированного гемоглобина ≤7,5%. Ожирение II степени. ХБП С4 А3. ТИА",
            ],
          },
          {
            type: "more",
            title:
              "Какие причины резистентной АГ присутствуют у пациента (выберите несколько вариантов):",
            answers: [
              "Хроническая болезнь почек",
              "Синдром обструктивного апноэ сна",
              "Поражение органов-мишеней",
              "Ожирение",
              "Применение сердечных гликозидов",
            ],
          },
          {
            type: "once",
            title:
              "Как часто данному пациенту рекомендовано посещать нефролога, учитывая стадию ХБП и уровень альбуминурии?",
            answers: [
              "1 раз в год",
              "2 раза в год",
              "3 раза в год",
              "4 раза в год",
            ],
          },
          {
            type: "once",
            title:
              "Какой целевой уровень АД рекомендован в данной клинической ситуации с учетом сопутствующей патологии?",
            answers: [
              "&lt;140/80 мм рт.ст., далее при хорошей переносимости, снижать САД до значений 130 мм рт.ст. и ниже",
              "&lt;130/80 мм рт.ст. и ниже",
              "≤150/80 мм рт.ст., далее при хорошей переносимости снижать САД до значений 140 мм рт.ст. и ниже",
            ],
          },
        ],
      ];
    },
    tips() {
      return [
        [
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: true,
              conditions: [["4"]],
              title: "Правильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["1", "2", "4"]],
              title: "Правильный ответ!",
              body: `
              `,
            },
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["2", "4", "5", "6"]],
              title: "Правильный ответ!",
              body: `
              `,
            },
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4", "5", "6"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
        ],
        [
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: true,
              conditions: [["4"]],
              title: "Правильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: true,
              conditions: [["1", "3", "4"]],
              title: "Правильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["2", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
            {
              status: false,
              conditions: [["1", "2", "3", "4", "5"]],
              title: "Неправильный ответ!",
              body: "",
            },
          ],
          [
            {
              status: false,
              conditions: [["1"]],
              title: "Неправильный ответ!",
              body: `
              
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Правильный ответ!",
              body: `
              
              `,
            },
            {
              status: false,
              conditions: [["4"]],
              title: "Неправильный ответ!",
              body: `
              
              `,
            },
          ],
          [
            {
              status: true,
              conditions: [["1"]],
              title: "Правильный ответ!",
              body: `
              
              `,
            },
            {
              status: false,
              conditions: [["2"]],
              title: "Неправильный ответ!",
              body: `
              
              `,
            },
            {
              status: true,
              conditions: [["3"]],
              title: "Неправильный ответ!",
              body: `
              
              `,
            },
          ],
        ],
      ];
    },
    modals() {
      return [
        {
          text: "Гипертоническая ангиоретинопатия выявляется в 40% случаев артериальной гипертензии. Заболевание проявляется изменениями глазного дна (сосудистыми аневризмами и кровоизлияниями, неравномерностью диаметра, извитости капилляров, отёком и отслойкой сетчатки.) и приводит к снижению остроты зрения и слепоте.",
        },
        {
          text: "Глобальная стандартизированная по возрасту распространённость ХБП, ассоциированная с АГ, составила 18%, терминальной ХБП, определяющей потребность в заместительной почечной терапии — 26%.",
        },
        {
          text: "При артериальной гипертензии происходит вовлечение сосудов разного калибра, что проявляется основной симптоматикой поражения органов-мишеней.",
        },
        {
          text: "У 77% людей, перенесших первый инсульт была артериальная гипертензия.<br><br>Высокое артериальное давление увеличивает риск инсульта в 4-6 раз.",
        },
        {
          text: "69% людей, перенесших острый инфаркт миокарда имели повышенное артериальное давление и знали об этом.",
        },
      ];
    },
  },
  methods: {
    refreshTest() {
      this.testKey++;
    },
    choiceFirstTab(tab) {
      this.$set(this, "firstTabsActive", tab);
      document
        .querySelector(".first-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".first-block__tabs-row").scrollLeft =
          document.querySelector(".first-block__tab_active").offsetLeft - 76;
      });
    },
    choiceSecondTab(tab) {
      this.$set(this, "secondTabsActive", tab);
      document
        .querySelector(".second-block.tab-pinned")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".second-block__tabs-row").scrollLeft =
          document.querySelector(".second-block__tab_active").offsetLeft - 76;
      });
    },

    choiceFourthTab(tab) {
      this.$set(this, "fourthTabsActive", tab);
      this.$set(this, "casesActiveTab", 0);
      this.refreshTest();
      document
        .querySelector(".fourth-block")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".fourth-block__tabs-row").scrollLeft =
          document.querySelector(".fourth-block__tab_active").offsetLeft - 76;
      });
    },

    changeCasesTabs(tab) {
      this.$set(this, "casesActiveTab", tab);
      document
        .querySelector(".fourth-block__tabs-body")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.$nextTick(() => {
        document.querySelector(".fourth-block__case-tabs-list").scrollLeft =
          document.querySelector(".fourth-block__case-tabs-tab_active")
            .offsetLeft - 76;
      });
    },
    fromTestToAnchor(anchor, e) {
      e.preventDefault();
      this.choiceSecondTab(2);
      this.$nextTick(() => {
        setTimeout(() => {
          if (document.querySelector(`#${anchor}`)) {
            document
              .querySelector(`#${anchor}`)
              .scrollIntoView({ block: "start", behavior: "smooth" });
          }
        }, 500);
      });
    },

    firstBlockTab1Hide() {
      this.$nextTick(() => {
        document
          .querySelector(".first-block__tab1-hide")
          .scrollIntoView({ block: "start" });
      });
    },
    secondBlockTab2Hide() {
      this.$nextTick(() => {
        document
          .querySelector(".second-block__tab2-hide")
          .scrollIntoView({ block: "start" });
      });
    },
    thirdBlockModalToggle(state, ind) {
      this.$set(this, "thirdBlockModal", state);
      this.$set(this, "thirdBlockCurrentModal", ind);
      bus.$emit("scrollLock", state);
    },
  },
  mounted() {
    window.addEventListener("load", () => {
      if (
        this.$route.hash &&
        document.getElementById(this.$route.hash.replace("#", ""))
      ) {
        document
          .getElementById(this.$route.hash.replace("#", ""))
          .scrollIntoView({ block: "start" });
      }
    });
    window.ag = this;
    window.addEventListener("scroll", function () {
      document.querySelectorAll(".fixed-tabs").forEach((t, i) => {
        if (!t.closest(".tab-pinned")) {
          return;
        }
        const parent = t.closest(".tab-pinned").getBoundingClientRect();
        // t.getBoundingClientRect().top <= 0
        if (parent.top < 0 && parent.top > parent.height * -1) {
          t.style.position = "sticky";
          t.classList.add("sticky");
        } else {
          t.style.position = "";
          t.classList.remove("sticky");
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/ag.scss";
</style>
<style lang="scss">
@import "@/assets/scss/ag_no-scoped.scss";
</style>